import React from "react";
import { Alert, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import { mutate } from "swr";
import BTable from "react-bootstrap/Table";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
    EditIcon,
    AngleSmallLeftIcon,
    StyledParagraph,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import store, { setGroupedArticles, setGroupName } from "./store";

import { SettingsArticlesContext } from "./context";

import useResource from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import NoImagePlaceholderIcon from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

export default function SecondStep({ go }: any) {
    const { t } = useTranslation();
    const { groupName } = useSnapshot(store);

    const { groups: articlesGroups } = React.useContext(
        SettingsArticlesContext
    );

    const [selectedArticlesList, setSelectedArticlesList] = React.useState<
        any[]
    >([]);
    const [newGroupDesignation, setNewGroupDesignation] = React.useState<
        string
    >(groupName);

    const [isGroupNameEmpty, setIsGroupNameEmpty] = React.useState<boolean>(
        false
    );

    const newGroupDesignationRef = React.useRef<HTMLInputElement>(null);

    const [
        isNameExistsInGroupsList,
        setIsNameExistsInGroupsList,
    ] = React.useState<boolean>(false);

    const [
        isGroupNameContainsSpecialStrings,
        setIsGroupNameContainsSpecialStrings,
    ] = React.useState<boolean>(false);

    const [selectedArticles, setSelectedArticles] = React.useState<any[]>([]);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${groupName}`
    );

    React.useEffect(() => {
        setSelectedArticlesList(resourceData.data);
    }, [resourceData]);

    const handleDeleteArticleButtonOnClickEvent = React.useCallback(
        async (article: any) => {
            swal({
                text: t("Are you sure you want to delete this article?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    let localSelectedArticles: any[] = [
                        ...selectedArticles,
                        article,
                    ];
                    setSelectedArticles(localSelectedArticles);
                    let localArticles: any[] = selectedArticlesList.filter(
                        (article: any) => {
                            return !localSelectedArticles
                                .map(
                                    (s) =>
                                        `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`
                                )
                                .includes(
                                    `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`
                                );
                        }
                    );
                    setSelectedArticlesList(localArticles);
                    let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group`;
                    let savedData = {
                        designation: newGroupDesignation,
                        groupment: localArticles.map((s) => {
                            return `${s.reference}|${s.designation}${
                                s.family.length > 0 ? `|${s.family}` : ""
                            }${
                                s.subFamily.length > 0 ? `|${s.subFamily}` : ""
                            }`;
                        }),
                    };
                    try {
                        NProgress.start();
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    NProgress.done();
                                    if (data.error) {
                                        throw Error(
                                            "Error while deleting an article in an articles group!"
                                        );
                                    }
                                    mutate(
                                        `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${newGroupDesignation}`
                                    );
                                    return swal({
                                        icon: "success",
                                        content: <p>{t(data.message)}!</p>,
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [selectedArticles, selectedArticlesList, newGroupDesignation, t]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (article: any) {
                    return (
                        <div>
                            {article.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                article.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Families"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: t("Sub-families"),
                accessor: "subFamily",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (article: any) {
                    return (
                        <div>
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                style={{
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block"
                                onClick={() =>
                                    handleDeleteArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none m-0"
                                icon="TrashIcon"
                                onClick={() =>
                                    handleDeleteArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleDeleteArticleButtonOnClickEvent]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: selectedArticlesList,

            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllPageRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    async function handleMultipleRemoveArticlesButtonOnClickEvent() {
        swal({
            text: t("Are you sure you want to delete this selection group?"),
            icon: "warning",
            buttons: [t("Cancel"), t("Delete")],
            dangerMode: true,
        }).then(async (willDelete: any) => {
            if (willDelete) {
                let localSelectedArticles: any[] = selectedFlatRows.map(
                    (row: any) => row.original
                );
                let localSelectedArticlesList = selectedArticlesList.map(
                    (s: any) =>
                        `${s.reference}|${s.designation}${
                            s.family.length > 0 ? `|${s.family}` : ""
                        }${s.subFamily.length > 0 ? `|${s.subFamily}` : ""}`
                );

                let articles: any[] = [];

                localSelectedArticlesList.forEach((article: string) => {
                    if (
                        !localSelectedArticles
                            .map(
                                (s: any) =>
                                    `${s.reference}|${s.designation}${
                                        s.family.length > 0
                                            ? `|${s.family}`
                                            : ""
                                    }${
                                        s.subFamily.length > 0
                                            ? `|${s.subFamily}`
                                            : ""
                                    }`
                            )
                            .includes(article)
                    ) {
                        articles.push(article);
                    }
                });

                setSelectedArticlesList(
                    selectedArticlesList.filter((article: any) => {
                        return !localSelectedArticles
                            .map((s) =>
                                `${s.franchise_name}|${s.shop_id}|${s.reference}`.toLowerCase()
                            )
                            .includes(
                                `${article.franchise_name}|${article.shop_id}|${article.reference}`.toLowerCase()
                            );
                    })
                );

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group`;
                let savedData = {
                    designation: newGroupDesignation,
                    groupment: articles,
                };

                try {
                    NProgress.start();

                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                NProgress.done();

                                if (data.error) {
                                    throw Error(
                                        "Error while removing multiple articles in an articles group!"
                                    );
                                }

                                mutate(
                                    `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${newGroupDesignation}`
                                );

                                return swal({
                                    icon: "success",
                                    content: <p>{t(data.message)}!</p>,
                                    buttons: false,
                                    timer: 2000,
                                });
                            })
                    );
                } catch (e: any) {
                    NProgress.done();
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    function handlePreviousStepAction() {
        go(0);
        setGroupedArticles([]);
    }

    function handleEditArticlesGroup() {
        setGroupedArticles(selectedArticlesList);
        go(4);
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    function toggleOpeningModal() {
        setIsGroupNameEmpty(false);
        setIsModalOpened(!isModalOpened);
        setIsNameExistsInGroupsList(false);
        setIsNameExistsInGroupsList(false);
    }

    function handleNewGroupDesignationInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        let specialStrings: string[] = [
            "\\",
            "+",
            "-",
            "&",
            "/",
            "*",
            "!",
            "?",
            "ç",
            "#",
            "~",
            "^",
            "@",
            '"',
            "]",
            "}",
            "#",
            "{",
            "[",
            "|",
            "-",
            "'",
        ];

        setIsGroupNameContainsSpecialStrings(
            specialStrings.filter((string: any) => {
                return e.target.value.includes(string);
            }).length > 0
        );

        setIsGroupNameEmpty(
            e.target.value.length === 0 || e.target.value.trim().length === 0
        );

        setIsNameExistsInGroupsList(
            articlesGroups.filter(
                (group: any) => group === e.target.value.trim()
            ).length > 0
        );
    }

    async function handleValidateNewGroupDesignationButtonOnClickEvent() {
        if (newGroupDesignationRef.current!.value!.length === 0) {
            setIsGroupNameEmpty(true);
        } else {
            toggleOpeningModal();
            setIsGroupNameEmpty(false);

            try {
                NProgress.start();

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group-name`;

                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            "old-name": groupName,
                            "new-name": newGroupDesignationRef.current!.value!,
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            NProgress.done();

                            setGroupName(
                                newGroupDesignationRef.current!.value!
                            );

                            setNewGroupDesignation(
                                newGroupDesignationRef.current!.value!
                            );

                            if (data.error) {
                                throw Error(
                                    "Error while editing products group name!"
                                );
                            }

                            return swal({
                                icon: "success",
                                content: (
                                    <p>{t("group updated successfully")}!</p>
                                ),
                            });
                        })
                );
            } catch (e: any) {
                setGroupName(groupName);
                setNewGroupDesignation(groupName);
                NProgress.done();
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                });
            }
        }
    }

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between align-items-md-center">
                        <div className="d-md-flex align-items-md-center mt-2 mt-md-2 mb-2">
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    icon="AngleSmallLeftIcon"
                                    className="m-0 p-0"
                                    onClick={handlePreviousStepAction}
                                >
                                    <AngleSmallLeftIcon
                                        height={35}
                                        width={35}
                                    />
                                </StyledIconButton>
                                <span className="mx-2 mb-0 font-size-14 white-space-nowrap h5">
                                    {newGroupDesignation}
                                </span>
                                <StyledIconButton
                                    icon="EditIcon"
                                    className="m-0"
                                    onClick={toggleOpeningModal}
                                >
                                    <EditIcon height={20} width={20} />
                                </StyledIconButton>
                            </div>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleRemoveArticlesButtonOnClickEvent
                                }
                            >
                                {t("Delete articles")}
                            </StyledButton>
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                onClick={handleEditArticlesGroup}
                            >
                                {t("Add articles")}
                            </StyledButton>
                        </div>
                    </div>
                    {page.length > 0 ? (
                        <React.Fragment>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {selectedArticlesList.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                    <Modal isOpen={isModalOpened} centered>
                        <ModalBody className="justify-content-center">
                            <StyledParagraph className="text-uppercase font-weight-bold text-center mt-4">
                                {t("Article groups")}
                            </StyledParagraph>
                            {isGroupNameEmpty ? (
                                <Alert className="mx-4" color="danger">
                                    {t("Please enter the name of the group")}
                                </Alert>
                            ) : null}
                            {isGroupNameContainsSpecialStrings ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t(
                                        "The name should not contain any special characters"
                                    )}
                                </Alert>
                            ) : null}
                            {isNameExistsInGroupsList ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t("This group name is used before")}
                                </Alert>
                            ) : null}
                            <AvForm>
                                <StyledTextInput
                                    id="articles-group-designation"
                                    name="articles-group-designation"
                                    placeholder={t("Enter the designation")}
                                    type="text"
                                    className="mt-5 ml-4"
                                    style={{ width: "90%" }}
                                    onChange={
                                        handleNewGroupDesignationInputOnChangeEvent
                                    }
                                />
                            </AvForm>
                        </ModalBody>
                        <ModalFooter className="justify-content-center mt-3">
                            <StyledButton
                                variant="primary"
                                outline
                                rounded
                                onClick={toggleOpeningModal}
                                className="w-25"
                            >
                                {t("Return")}
                            </StyledButton>
                            <StyledButton
                                variant="primary"
                                outline={false}
                                rounded
                                onClick={
                                    handleValidateNewGroupDesignationButtonOnClickEvent
                                }
                                disabled={
                                    isGroupNameContainsSpecialStrings ||
                                    isGroupNameEmpty ||
                                    isGroupNameContainsSpecialStrings ||
                                    isNameExistsInGroupsList
                                }
                                className="w-25"
                            >
                                {t("Validate")}
                            </StyledButton>
                        </ModalFooter>
                    </Modal>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
