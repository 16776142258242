import React from "react";

export default function ClickAndCollectSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8779">
                <g data-name="Groupe 8757">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g fill="#fff">
                    <path
                        data-name="Trac\xE9 8547"
                        d="M26.575 13.625a.937.937 0 000-1.875H21.45a.937.937 0 000 1.875z"
                    />
                    <path
                        data-name="Trac\xE9 8548"
                        d="M30.343 24.26h3.314a.937.937 0 100-1.875h-3.314a.937.937 0 100 1.875z"
                    />
                    <path
                        data-name="Trac\xE9 8549"
                        d="M40 22.072a3.443 3.443 0 00-2.927-3.4v-7.236A3.441 3.441 0 0033.638 8h-19.25a3.441 3.441 0 00-3.438 3.437v14.19l-2.516 3.379a2.187 2.187 0 001.755 3.494H24v4.062A3.441 3.441 0 0027.437 40h9.125A3.441 3.441 0 0040 36.562zm-3.438 16.053h-9.125a1.564 1.564 0 01-1.562-1.562v-.313h12.25v.312a1.564 1.564 0 01-1.563 1.563zM25.875 22.072a1.564 1.564 0 011.562-1.562h9.125a1.564 1.564 0 011.562 1.562v12.3H25.875zm-13.05-10.635a1.564 1.564 0 011.563-1.562h19.25a1.564 1.564 0 011.562 1.562v7.2h-7.76A3.441 3.441 0 0024 22.072V25H12.825zm-2.636 19.188a.313.313 0 01-.251-.5l2.42-3.251H24v3.75z"
                    />
                </g>
            </g>
        </svg>
    );
}
