import React from "react";
import { Modal, Popover } from "reactstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import DateRangePickerWrapper from "@components/Common/DateRangePickerWrapper";
import MobileDateRangePickerWrapper from "@components/Common/MobileDateRangePickerWrapper";

import "./index.css";

type PropsType = {
    selectedItem: string;
};

const StyledPopoverBody = styled.div`
    width: 558px;
    height: 515px;
    background-color: white;
    box-shadow: 0px 11px 14px 7px rgba(0, 0, 0, 0.11);
`;

const StatisticsOutOfStockStyledPopoverBody = styled.div`
    width: 558px;
    height: 435px;
    background-color: white;
    box-shadow: 0px 11px 14px 7px rgba(0, 0, 0, 0.11);
`;

export default function PeriodModalWrapper({ selectedItem }: PropsType) {
    const { pathname } = useLocation();
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [isPopoverOpened, setIsPopoverOpened] = React.useState(false);

    function toggleOpenedPopover() {
        setIsPopoverOpened(!isPopoverOpened);
    }

    function toggleOpeningModal() {
        setIsModalOpened(!isModalOpened);
    }

    return (
        <React.Fragment>
            <button
                id="date-range-picker-popover"
                type="button"
                className="font-size-16 text-dark bg-transparent border-0 mr-2 px-0"
                onClick={!isMobile ? toggleOpenedPopover : toggleOpeningModal}
            >
                <h5
                    style={{
                        textDecoration: "underline",
                    }}
                    className="white-space-nowrap"
                >
                    {selectedItem}
                </h5>
            </button>
            {!isMobile ? (
                <Popover
                    placement="top"
                    isOpen={isPopoverOpened}
                    target="date-range-picker-popover"
                    toggle={toggleOpenedPopover}
                    trigger="legacy"
                >
                    {pathname === "/statistics-out-of-stock" ? (
                        <StatisticsOutOfStockStyledPopoverBody>
                            <DateRangePickerWrapper
                                toggleOpenedPopover={toggleOpenedPopover}
                            />
                        </StatisticsOutOfStockStyledPopoverBody>
                    ) : (
                        <StyledPopoverBody>
                            <DateRangePickerWrapper
                                toggleOpenedPopover={toggleOpenedPopover}
                            />
                        </StyledPopoverBody>
                    )}
                </Popover>
            ) : (
                <Modal
                    isOpen={isModalOpened}
                    toggle={toggleOpeningModal}
                    className="defined-range-modal__clz"
                    centered
                    fade={false}
                >
                    <MobileDateRangePickerWrapper
                        toggleOpeningModal={toggleOpeningModal}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
}
