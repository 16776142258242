import React from "react";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function PercentageTop10ArticlesCol() {
    const { t } = useTranslation();
    const { period, type, advancedDate, selections } = React.useContext(
        StatisticsContext
    );

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/out-of-stock/products/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSeries: number[] = [];
        let localProducts: string[] = [];

        resourceData.data?.forEach((item: any) => {
            localSeries.push(Number(item.value));
            localProducts.push(item.designation);
        });

        setData(localSeries);

        setLabels(localProducts.length > 0 ? localProducts : [t("No data")]);
    }, [resourceData, t]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Products")}
                        data={{
                            data,
                            labels,
                        }}
                    >
                        <LegendPopoverWrapper
                            title={t("Products")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
