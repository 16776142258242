import React from "react";
import { Card, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useStep } from "react-hooks-helper";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { StyledStepButton } from "@aureskonnect/react-ui";

import { resetRegulation } from "@store/actions";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

import "@assets/widths.css";

export default function Regulation() {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { index, navigation } = useStep({ steps: 3 });

    return (
        <Card>
            <CardTitle
                className="d-flex justify-content-center justify-content-md-start mb-0"
                style={{ backgroundColor: "black" }}
            >
                <div className="d-md-flex steps__clz mx-3 ml-md-3 mr-md-0">
                    <StyledStepButton
                        color={index === 0 ? "#fefefe" : "#6a7187"}
                        rounded
                        step="in progress"
                        stepDetail={t("Please select/create the group")}
                        stepNumber={1}
                        stepTitle="Step"
                        className={classnames({
                            "my-2 active-menu__clz btn w-sm-100 w-md-unset":
                                index === 0,

                            "my-2 disable-menu__clz btn w-sm-100 w-md-unset":
                                index !== 0,
                        })}
                        onClick={() => {
                            dispatch(resetRegulation());

                            navigation.go(0);
                        }}
                    />
                    <StyledStepButton
                        color={index === 1 ? "#fefefe" : "#6a7187"}
                        rounded
                        step="in progress"
                        stepDetail={t("Please select the regulations")}
                        stepNumber={2}
                        stepTitle="Step"
                        className={classnames({
                            "my-2 mx-md-3 active-menu__clz btn w-sm-100 w-md-unset":
                                index === 1 || index === 2,

                            "my-2 mx-md-3 disable-menu__clz btn w-sm-100 w-md-unset":
                                index !== 1 && index !== 2,
                        })}
                        disabled={index === 0}
                        onClick={() => navigation.go(1)}
                    />
                </div>
            </CardTitle>

            {index === 0 && (
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <FirstStep {...navigation} />
                    </CustomErrorBoundary>
                </React.Suspense>
            )}
            {index === 1 && (
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SecondStep {...navigation} />
                    </CustomErrorBoundary>
                </React.Suspense>
            )}
            {index === 2 && (
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <ThirdStep {...navigation} />
                    </CustomErrorBoundary>
                </React.Suspense>
            )}
        </Card>
    );
}
