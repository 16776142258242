import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { usePagination, useTable, useRowSelect } from "react-table";
import BTable from "react-bootstrap/Table";
import styled from "styled-components";

import { removeProductItem } from "@pages/Statistics/Transactions/store";

import { ITEMS_PER_PAGE } from "@constants/index";

import { ArrowLeftSvgIcon } from "@components/Common/SvgIcons";
import CustomSelect from "@pages/Settings/Articles/CustomSelect";

import Pagination from "../Table/Pagination";

const Styles = styled.div`
    table {
        th:first-child {
            width: 5%;
        }
    }
`;

type PropsType = {
    products: any[];
    previous: () => void;
};

export default function MySelection({ products, previous }: PropsType) {
    const { t } = useTranslation();

    const productsSearchInputRef = React.useRef<HTMLInputElement>(null);

    const [familiesSelectedValue, setFamiliesSelectedValue] = React.useState<
        any[]
    >([]);
    const [
        subFamiliesSelectedValue,
        setSubFamiliesSelectedValue,
    ] = React.useState<any[]>([]);

    const [filteredProducts, setFilteredProducts] = React.useState<any[]>([]);
    const [families, setFamilies] = React.useState<any[]>([]);
    const [subFamilies, setSubFamilies] = React.useState<any[]>([]);

    function handleSearchInputOnChangeEvent(searchedValue: string) {
        let localProducts: any[];

        if (
            familiesSelectedValue !== null &&
            familiesSelectedValue!.length > 0
        ) {
            localProducts = products.filter((product) => {
                return (
                    product.designation
                        .toUpperCase()
                        .includes(searchedValue.toUpperCase()) &&
                    familiesSelectedValue
                        .map((element) => element.value.toUpperCase())
                        .includes(product.families.toUpperCase())
                );
            });
        } else if (
            subFamiliesSelectedValue !== null &&
            subFamiliesSelectedValue!.length > 0
        ) {
            localProducts = products.filter((product) => {
                return (
                    product.designation
                        .toUpperCase()
                        .includes(searchedValue.toUpperCase()) &&
                    subFamiliesSelectedValue
                        .map((element) => element.value.toUpperCase())
                        .includes(product.sub_families.toUpperCase())
                );
            });
        } else {
            localProducts = products.filter((product) => {
                return product.designation
                    .toUpperCase()
                    .includes(searchedValue.toUpperCase());
            });
        }
        setFilteredProducts(localProducts);
    }

    function handleRemoveMultipleSelectedProduct() {
        selectedFlatRows.forEach((row: any) => {
            removeProductItem(row.original);
        });

        let localProducts: any[] = filteredProducts.filter((product) => {
            return !selectedFlatRows
                .map((row: any) => row.original)
                .map((s) =>
                    `${s.reference}|${s.designation}|${s.families}|${s.sub_families}`.toLowerCase()
                )
                .includes(
                    `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase()
                );
        });

        setFilteredProducts(localProducts);
    }

    const handleRemoveSelectedProduct = React.useCallback(
        (product: any) => {
            let localProducts: any[] = filteredProducts.filter((datum: any) => {
                return (
                    `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase() !==
                    `${datum.reference}|${datum.designation}|${datum.families}|${datum.sub_families}`.toLowerCase()
                );
            });
            setFilteredProducts(localProducts);
            removeProductItem(product);
        },
        [filteredProducts]
    );

    function handleFamiliesSelectOnChangeEvent(
        familiesSelectedValue: any,
        action: any
    ) {
        setFilteredProducts(products);
        setFamiliesSelectedValue(familiesSelectedValue);
        setSubFamiliesSelectedValue([]);

        if (action.action === "clear") {
            setFilteredProducts(products);
        } else if (familiesSelectedValue) {
            let filteredProductsByFamilies: any[] = [];
            familiesSelectedValue!.forEach((item: any) => {
                products.forEach((product) => {
                    if (product.families === item.value) {
                        filteredProductsByFamilies.push(product);
                    }
                });
            });
            productsSearchInputRef!.current!.value = "";
            setFilteredProducts(filteredProductsByFamilies);
        }
    }

    function handleSubFamiliesSelectOnChangeEvent(
        subFamiliesSelectedValue: any,
        action: any
    ) {
        setSubFamiliesSelectedValue(subFamiliesSelectedValue);
        setFilteredProducts(products);
        setFamiliesSelectedValue([]);

        if (action.action === "clear") {
            setFilteredProducts(products);
        } else if (subFamiliesSelectedValue) {
            let filteredProductsBySubFamilies: any[] = [];
            subFamiliesSelectedValue!.forEach((item: any) => {
                products.forEach((product) => {
                    if (product.sub_families === item.value) {
                        filteredProductsBySubFamilies.push(product);
                    }
                });
            });
            productsSearchInputRef!.current!.value = "";
            setFilteredProducts(filteredProductsBySubFamilies);
        }
    }

    const columns: AdvancedTableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Families"),
                accessor: "families",
            },
            {
                Header: t("Sub-families"),
                accessor: "sub_families",
            },
            {
                Header: "",
                id: "actions",
                accessor: function (product: any) {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="secondary"
                                outline
                                block
                                style={{
                                    width: "150px",
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block p-1"
                                onClick={() =>
                                    handleRemoveSelectedProduct(product)
                                }
                            >
                                {t("Delete")}
                            </Button>
                            <button
                                className="border-0 d-block d-sm-block d-md-none p-1 bg-transparent__clz icon-color__clz"
                                onClick={() =>
                                    handleRemoveSelectedProduct(product)
                                }
                            >
                                <i className="bx bxs-trash-alt font-size-24"></i>
                            </button>
                        </div>
                    );
                },
            },
        ],
        [t, handleRemoveSelectedProduct]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t(rest.title) };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredProducts,
            initialState: { pageIndex: 0 },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        let localFamilies: string[] = [];
        let localSubFamilies: string[] = [];

        products.forEach((product: any) => {
            if (product.families !== "") {
                localFamilies.push(product.families);
            }

            if (product.sub_families !== "") {
                localSubFamilies.push(product.sub_families);
            }
        });

        setFilteredProducts(products);

        setFamilies([...new Set(localFamilies)]);
        setSubFamilies([...new Set(localSubFamilies)]);
    }, [products]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <ArrowLeftSvgIcon
                        style={{
                            cursor: "pointer",
                        }}
                        className="ml-2"
                        onClick={() => previous()}
                    />
                    <h5 className="ml-3 mb-0 white-space-nowrap">{`${t(
                        "My selection"
                    )} (${products.length})`}</h5>
                </div>
                <Button
                    type="button"
                    color="secondary"
                    className="m-2 white-space-nowrap"
                    disabled={selectedFlatRows.length === 0}
                    onClick={handleRemoveMultipleSelectedProduct}
                >
                    {t("Delete")}
                </Button>
            </div>

            <div className="d-flex mb-2">
                <div className="app-search p-0 mx-2">
                    <div className="position-relative">
                        <input
                            ref={productsSearchInputRef}
                            type="text"
                            className="form-control"
                            placeholder={`${t("Search")} ...`}
                            onChange={(e) =>
                                handleSearchInputOnChangeEvent(e.target.value)
                            }
                        />

                        <span className="bx bx-search-alt"></span>
                    </div>
                </div>
                <CustomSelect
                    options={
                        families.map((family: string) => ({
                            label: family,
                            value: family,
                        })) as any
                    }
                    placeholder={t("Families")}
                    handleOnChangeEvent={handleFamiliesSelectOnChangeEvent}
                    selectedValue={familiesSelectedValue}
                />
                <CustomSelect
                    options={
                        subFamilies.map((family: string) => ({
                            label: family,
                            value: family,
                        })) as any
                    }
                    placeholder={t("Sub-families")}
                    handleOnChangeEvent={handleSubFamiliesSelectOnChangeEvent}
                    selectedValue={subFamiliesSelectedValue}
                />
            </div>
            <Styles>
                <BTable responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        className="white-space-nowrap"
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                className="white-space-nowrap"
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </Styles>
            {filteredProducts.length > ITEMS_PER_PAGE ? (
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageIndex={pageIndex}
                />
            ) : null}
        </React.Fragment>
    );
}
