import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import store from "../store";

import SalesMode from "../SalesMode";
import Products from "../Products";
import SegmentContent from "../SegmentContent";
import SalesSupport from "../SalesSupport";
import AverageBasket from "../AverageBasket";
import RegulationModes from "../RegulationModes";
import CustomersCities from "../CustomersCities";
import TransactionAmount from "../TransactionAmount";

import "../index.css";

export default function NewSegment() {
    const { t } = useTranslation();

    const advancedStore = useSnapshot(store);

    const tabs: TabsType[] = [
        {
            title:
                advancedStore.segmentName !== "" &&
                advancedStore.salesModeSelection.length > 0
                    ? `${t("Sales mode")} (${
                          advancedStore.salesModeSelection.length
                      })`
                    : t("Sales mode"),
            content: <SalesMode title={t("Sales mode")} />,
        },
        {
            title:
                advancedStore.segmentName !== "" &&
                advancedStore.salesSupportSelection.length > 0
                    ? `${t("Sales support")} (${
                          advancedStore.salesSupportSelection.length
                      })`
                    : t("Sales support"),
            content: <SalesSupport title={t("Sales support")} />,
        },
        {
            title:
                advancedStore.segmentName !== "" &&
                advancedStore.regulationModesSelection.length > 0
                    ? `${t("Payment methods")} (${
                          advancedStore.regulationModesSelection.length
                      })`
                    : t("Payment methods"),
            content: <RegulationModes title={t("Payment methods")} />,
        },
        { title: t("Average basket"), content: <AverageBasket /> },
        {
            title:
                advancedStore.segmentName !== "" &&
                advancedStore.productsSelection.length > 0
                    ? `${t("Products")} (${
                          advancedStore.productsSelection.length
                      })`
                    : t("Products"),
            content: <Products title={t("Products")} />,
        },
        {
            title:
                advancedStore.segmentName !== "" &&
                advancedStore.customersCitiesSelection.length > 0
                    ? `${t("Order city")} (${
                          advancedStore.customersCitiesSelection.length
                      })`
                    : t("Order city"),
            content: <CustomersCities title={t("Order city")} />,
        },
        {
            title: t("Transaction amount"),
            content: <TransactionAmount />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleActiveTab(tab: number) {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="d-md-flex">
            <Nav
                tabs
                className="w-sm-100 nav-tab-width__clz flex-md-column border-bottom-none__clz"
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            className={classnames("nav-item-hover__clz", {
                                "nav-item-active__clz": activeTab === index,
                            })}
                            key={uid(element, index)}
                            onClick={() => {
                                toggleActiveTab(index);
                            }}
                        >
                            <NavLink
                                className={classnames(
                                    "disable-nav-link-hover",
                                    {
                                        "active nav-link-new-segment-active__clz active-tab__clz":
                                            activeTab === index,
                                    }
                                )}
                            >
                                {element.title}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab} className="w-100">
                {tabs.map((element, index) => {
                    return (
                        <TabPane tabId={index} key={uid(element, index)}>
                            <SegmentContent
                                title={element.title}
                                content={element.content}
                            />
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
