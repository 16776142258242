import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
} from "reactstrap";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import Families from "../Families";
import SubFamilies from "../SubFamilies";
import Articles from "../Articles";
import Regulation from "../Regulation";

import "@assets/widths.css";
import "@assets/nav.css";

export default function MainRow() {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = React.useState<number>(1);

    function toggle(tab: number): void {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            {t("List of objects to be treated")}
                        </CardTitle>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        "active active-tab__clz":
                                            activeTab === 1,
                                    })}
                                    onClick={() => {
                                        toggle(1);
                                    }}
                                    style={{ color: "black" }}
                                >
                                    {t("The regulations")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        "active active-tab__clz":
                                            activeTab === 2,
                                    })}
                                    onClick={() => {
                                        toggle(2);
                                    }}
                                    style={{ color: "black" }}
                                >
                                    {t("Families")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        "active active-tab__clz":
                                            activeTab === 3,
                                    })}
                                    onClick={() => {
                                        toggle(3);
                                    }}
                                    style={{ color: "black" }}
                                >
                                    {t("Sub-families")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        "active active-tab__clz":
                                            activeTab === 4,
                                    })}
                                    onClick={() => {
                                        toggle(4);
                                    }}
                                    style={{ color: "black" }}
                                >
                                    {t("Products")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={activeTab}
                            className="mt-3"
                            style={{
                                boxShadow: "5px 15px 16px 0px #e0e0e0",
                            }}
                        >
                            <TabPane tabId={1}>
                                <Row>
                                    <Col sm="12">
                                        <React.Suspense
                                            fallback={<SpinnerWrapper />}
                                        >
                                            <Regulation />
                                        </React.Suspense>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Row>
                                    <Col sm="12">
                                        <Families />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                                <Row>
                                    <Col sm="12">
                                        <SubFamilies />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={4}>
                                <Row>
                                    <Col sm="12">
                                        <Articles />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
