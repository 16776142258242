import React from "react";
import {
    Modal,
    ModalBody,
    Input,
    Alert,
    ModalFooter,
    Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store, { setCheckedFranchises } from "@pages/MySelections/store";

import { getSelection } from "@helpers/general";

type PropsType = {
    isModalOpened: boolean;
    toggleOpeningModal: () => void;
    sendSavingSelectionRequest: Function;
    options: any[];
};

export default function SaveMySelectionModalWrapper({
    isModalOpened,
    toggleOpeningModal,
    sendSavingSelectionRequest,
    options,
}: PropsType) {
    const { t } = useTranslation();
    const { selection, setSelection } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const [
        isNameExistsInSelections,
        setIsNameExistsInSelections,
    ] = React.useState<boolean>(false);
    const [
        isNameContainsSpecialStrings,
        setIsNameContainsSpecialStrings,
    ] = React.useState<boolean>(false);

    function handleSelectionInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setSelection!(e.target.value);

        let specialStrings: string[] = [
            "\\",
            "+",
            "-",
            "&",
            "/",
            "*",
            "!",
            "?",
            "ç",
            "#",
            "~",
            "^",
            "@",
            '"',
            "]",
            "}",
            "#",
            "{",
            "[",
            "|",
            "-",
            "'",
            ",",
        ];

        setIsNameExistsInSelections(
            options.filter(
                (selection: any) => selection.label === e.target.value
            ).length > 0
        );

        setIsNameContainsSpecialStrings(
            specialStrings.filter((selection: any) => {
                return e.target.value.includes(selection);
            }).length > 0
        );
    }

    function handleValidateSelectionButtonOnClickEvent() {
        toggleOpeningModal();
        setCheckedFranchises([]);
        let selectionBody: SelectionType = getSelection(
            MySelectionsStore.checkedFranchises,
            selection!
        );
        sendSavingSelectionRequest(selectionBody);
        setSelection!("");
    }

    return (
        <Modal
            isOpen={isModalOpened}
            onClosed={() => {
                setIsNameExistsInSelections(false);
                setIsNameContainsSpecialStrings(false);
                setSelection!("");
            }}
            centered
        >
            <ModalBody className="justify-content-center">
                <p className="text-uppercase font-size-14 ml-5 mt-4">
                    {t("Please enter the name of your selection")}
                </p>
                <Input
                    type="text"
                    value={selection}
                    placeholder={t("Enter")}
                    className="mt-5 ml-4"
                    style={{ width: "90%" }}
                    onChange={handleSelectionInputOnChangeEvent}
                />
                {isNameExistsInSelections ? (
                    <Alert className="mx-4 mt-2" color="danger">
                        {t("This name is used in an existing selection")}
                    </Alert>
                ) : null}
                {isNameContainsSpecialStrings ? (
                    <Alert className="mx-4 mt-2" color="danger">
                        {t(
                            "The name should not contain any special characters"
                        )}
                    </Alert>
                ) : null}
            </ModalBody>
            <ModalFooter className="justify-content-center mt-3">
                <Button
                    color="secondary"
                    outline
                    onClick={toggleOpeningModal}
                    className="w-25"
                >
                    {t("Return")}
                </Button>
                <Button
                    disabled={
                        isNameContainsSpecialStrings || isNameExistsInSelections
                    }
                    type="button"
                    color="dark"
                    onClick={handleValidateSelectionButtonOnClickEvent}
                    className="w-25"
                >
                    {t("Validate")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
