import React from "react";
import { Card, CardBody, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getRegulationModesQueryString,
    getStatisticsData,
    getTypeParam,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";

import DropdownWrapper from "@pages/Statistics/DropdownWrapper";
import SmoothCurveLine from "@pages/Statistics/CommonCharts/SmoothCurveLine";

export default function StatisticsServicesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [servicesData, setServicesData] = React.useState<SeriesDataType[]>(
        []
    );
    const [categories, setCategories] = React.useState<string[]>([]);

    const {
        resourceData: statisticsServicesData,
        error: statisticsServicesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/services?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getRegulationModesQueryString(
                statisticsStore.regulationModesCards.modes
            ).length > 0
                ? `&regulation=${getRegulationModesQueryString(
                      statisticsStore.regulationModesCards.modes
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsServicesData.data);
        setServicesData(
            getStatisticsData(
                statisticsServicesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsServicesData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
    ]);

    const servicesSeries: string[] = [
        t("Midday service"),
        t("Evening service"),
    ];

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm">
                <Nav pills className="d-none navtab-bg nav__clz nav__clz-sm">
                    <DropdownWrapper
                        options={[
                            `${t("All")} ${t("Services")}`,
                            ...servicesSeries,
                        ]}
                    />
                    <Link
                        style={{ paddingTop: "8px" }}
                        to="/coming-soon"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <div className="apex-charts">
                    {statisticsServicesDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <SmoothCurveLine
                                series={servicesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
