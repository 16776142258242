import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store from "@pages/MySelections/store";

import {
    clearRegulationModes,
    clearSalesMode,
    clearSalesSupport,
    selectShops,
} from "@store/actions";

import { getFavoritesObj, getSelection } from "@helpers/general";

type PropsType = {
    sendSavingSelectionRequest: Function;
};

export default function ValidateSelectionButtonWrapper({
    sendSavingSelectionRequest,
}: PropsType) {
    const { t } = useTranslation();
    const { selectedSelection, selection } =
        React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const favorites = useSelector(
        (state: { favorites: any[] }) => state.favorites
    );

    const history = useHistory();
    const dispatch = useDispatch();

    function convertStringDataToNumber(selection: any): any {
        for (var i = 0; i < selection.selection?.length; i++) {
            for (var j = 0; j < selection.selection[i].shops_id.length; j++) {
                selection.selection[i].shops_id[j] = parseInt(
                    selection.selection[i].shops_id[j]
                );
            }
        }
        return selection;
    }

    async function validateSelection() {
        if (
            Object.keys(selectedSelection).length === 0 &&
            favorites.length !== 0 &&
            MySelectionsStore.checkedFranchises.length === 0
        ) {
            dispatch(selectShops(getFavoritesObj(favorites)));
            sendSavingSelectionRequest(getFavoritesObj(favorites), true);
        }

        if (
            Object.keys(selectedSelection).length === 0 &&
            favorites.length === 0 &&
            MySelectionsStore.checkedFranchises.length === 0
        ) {
            let allFranchises: any = [];
            MySelectionsStore.filteredFranchises?.forEach(
                (el: any, index: number) => {
                    let shopsIds: any = [];
                    el.shops?.forEach((element: any) => {
                        shopsIds.push(element.id);
                    });
                    allFranchises[index] = {
                        franchise_id: el.id,
                        shops_id: shopsIds,
                    };
                }
            );

            dispatch(
                selectShops(
                    convertStringDataToNumber({
                        designation: "",
                        selection: allFranchises,
                    })
                )
            );
            sendSavingSelectionRequest(getFavoritesObj(favorites), true);
        }

        if (Object.keys(selectedSelection).length > 0) {
            let array: any = [];
            MySelectionsStore.filteredFranchises.forEach((selection: any) => {
                let localShop: any[] = [];
                selection.shops.forEach((el: any) => {
                    localShop.push(Number(el.id));
                });
                array.push({
                    franchise_id: selection.id,
                    shops_id: localShop,
                });
            });
            sendSavingSelectionRequest(getFavoritesObj(favorites), true);
            dispatch(
                selectShops(
                    convertStringDataToNumber({
                        designation: `${selectedSelection.map(
                            (selection: any) => selection.label
                        )}`,
                        selection: array,
                    })
                )
            );
        } else if (MySelectionsStore.checkedFranchises.length > 0) {
            let tempSelection = getSelection(
                MySelectionsStore.checkedFranchises,
                selection!
            );

            let user: any = JSON.parse(localStorage.getItem("authUser")!);

            tempSelection.designation = `temp_selection_${user?.id}`;
            dispatch(selectShops(convertStringDataToNumber(tempSelection)));
            await sendSavingSelectionRequest(tempSelection, true);
            sendSavingSelectionRequest(
                convertStringDataToNumber(getFavoritesObj(favorites)),
                true
            );
        }
        dispatch(clearSalesSupport());
        dispatch(clearSalesMode());
        dispatch(clearRegulationModes());

        history.push("/dashboard");
    }

    return (
        <Button
            type="button"
            color="success"
            className="px-0 w-xs-100 w-sm-100 mt-2 mt-md-0 franchises-font-size-xl__clz"
            onClick={validateSelection}
        >
            {t("Validate my selection")}
        </Button>
    );
}
