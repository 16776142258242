import { proxy } from "valtio";

type SettingsArticlesStoreType = {
    groupName: string;
    groupedArticles: any[];
};

const store = proxy<SettingsArticlesStoreType>({
    groupName: "",
    groupedArticles: [],
});

export function setGroupName(groupName: string) {
    store.groupName = groupName;
}

export function setGroupedArticles(groupedArticles: any[]) {
    store.groupedArticles = groupedArticles;
}

export default store;
