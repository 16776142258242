import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import {
    setEmployeesQueryString,
    clearStore,
} from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

type LocalOptionsType = {
    label: string | number;
    value: string | number;
    _id?: string | number;
};

export default function CustomSelectFilter({
    options,
    employees,
}: {
    options: any;
    employees?: any;
}) {
    const { t } = useTranslation();

    const {
        setTop10FamiliesSelection,
        setTop10SubFamiliesSelection,
        setTop10ProductsSelection,
    } = React.useContext(StatisticsContext);

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        options!.map((option: string) => ({
            label: option,
            value: option,
        }))[0],
    ]);

    function onSelectChange(selectedValue: any, actionType: any) {
        let isAllEmployeesSelected: boolean =
            selectedValue.filter((el: any) => {
                return el.label !== t("All employees");
            }).length ===
                employees?.length - 1 && options.length === 0;

        if (
            actionType.action === "clear" ||
            actionType.action === "remove-value" ||
            isAllEmployeesSelected
        ) {
            setSelectedValueState([
                options.length > 0
                    ? options!.map((option: string) => ({
                          label: option,
                          value: option,
                      }))[0]
                    : employees?.map((option: any) => {
                          let obj: any = {
                              label: option.designation,
                              value: option.designation,
                          };

                          if (option.code !== undefined) {
                              obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                          }

                          return obj;
                      })[0],
            ]);
            return false;
        }

        let allExistInSelectedValue: boolean =
            selectedValue?.find((element: LocalOptionsType) => {
                return (
                    element.value ===
                    (options.length > 0
                        ? options!.map((option: string) => ({
                              label: option,
                              value: option,
                          }))[0]
                        : employees?.map((option: any) => {
                              let obj: any = {
                                  label: option.designation,
                                  value: option.designation,
                              };

                              if (option.code !== undefined) {
                                  obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                              }

                              return obj;
                          })[0]
                    ).value
                );
            }) !== undefined;

        if (allExistInSelectedValue) {
            if (
                [
                    t("Top 10 families"),
                    t("Top 10 sub-families"),
                    t("Top 10 products"),
                    t("All employees"),
                ].includes(actionType.option.label)
            ) {
                setSelectedValueState([
                    options.length > 0
                        ? options!.map((option: string) => ({
                              label: option,
                              value: option,
                          }))[0]
                        : employees?.map((option: any) => {
                              let obj: any = {
                                  label: option.designation,
                                  value: option.designation,
                              };

                              if (option.code !== undefined) {
                                  obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                              }

                              return obj;
                          })[0],
                ]);
            } else {
                let opts =
                    selectedValue.length > 1
                        ? selectedValue.filter(
                              (opt: any) =>
                                  ![
                                      t("Top 10 families"),
                                      t("Top 10 sub-families"),
                                      t("Top 10 products"),
                                      t("All employees"),
                                  ].includes(opt.label)
                          )
                        : [selectedValue];

                setSelectedValueState(opts);
            }
        } else {
            if (
                [
                    t("Top 10 families"),
                    t("Top 10 sub-families"),
                    t("Top 10 products"),
                    t("All employees"),
                ].includes(actionType.option.label)
            ) {
                setSelectedValueState([
                    options.length > 0
                        ? options!.map((option: string) => ({
                              label: option,
                              value: option,
                          }))[0]
                        : employees?.map((option: any) => {
                              let obj: any = {
                                  label: option.designation,
                                  value: option.designation,
                              };

                              if (option.code !== undefined) {
                                  obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                              }

                              return obj;
                          })[0],
                ]);
            } else {
                let selectedValueSize = Object.keys(selectedValue)?.length;

                if (
                    selectedValueSize ===
                        (options.length > 0
                            ? options.length - 1
                            : employees?.length) ||
                    selectedValueSize === 0
                ) {
                    setSelectedValueState([
                        options.length > 0
                            ? options!.map((option: string) => ({
                                  label: option,
                                  value: option,
                              }))[0]
                            : employees?.map((option: any) => {
                                  let obj: any = {
                                      label: option.designation,
                                      value: option.designation,
                                  };

                                  if (option.code !== undefined) {
                                      obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                                  }

                                  return obj;
                              })[0],
                    ]);
                } else {
                    if (options.length > 0) {
                        setSelectedValueState(selectedValue);
                    } else {
                        setSelectedValueState((prevState: any) => {
                            if (
                                prevState.filter(
                                    (opt: any) =>
                                        opt._id === actionType.option._id
                                ).length === 0
                            ) {
                                return [...prevState, actionType.option];
                            } else {
                                return prevState.filter(
                                    (opt: any) =>
                                        opt._id !== actionType.option._id
                                );
                            }
                        });
                    }
                }
            }
        }
    }

    const Option = ({ getValue, setValue, ...props }: any) => {
        return (
            <components.Option {...props}>
                <div className="d-flex justify-content-between">
                    <label>{props.value}</label>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        readOnly
                    />
                </div>
            </components.Option>
        );
    };

    const ValueContainer = ({ children, getValue, ...props }: any) => {
        return (
            <components.ValueContainer {...props}>
                {t("Top 10 families") === props.selectProps.value[0]?.label &&
                getValue().length === options.length - 1
                    ? t("Top 10 families")
                    : t("Top 10 sub-families") ===
                          props.selectProps.value[0]?.label &&
                      getValue().length === options.length - 1
                    ? t("Top 10 sub-families")
                    : t("Top 10 products") ===
                          props.selectProps.value[0]?.label &&
                      getValue().length === options.length - 1
                    ? t("Top 10 products")
                    : t("All employees") ===
                          props.selectProps.value[0]?.label &&
                      getValue().length === options.length - 1
                    ? t("All employees")
                    : getValue().length > 1
                    ? t("Selected element", {
                          selectedItems: getValue().length,
                          count: getValue().length,
                      })
                    : children}

                {React.Children.map(children, (child) => {
                    return child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    React.useEffect(() => {
        setSelectedValueState([
            options.length > 0
                ? options!.map((option: string) => ({
                      label: option,
                      value: option,
                  }))[0]
                : employees?.map((option: any) => {
                      let obj: any = {
                          label: option.designation,
                          value: option.designation,
                      };

                      if (option.code !== undefined) {
                          obj._id = `${option.code!}|${option.shop_id!}|${option.schema!}`;
                      }

                      return obj;
                  })[0],
        ]);
    }, [employees, options]);

    React.useEffect(() => {
        let filteredSelectedValue: string[] = selectedValueState
            .filter((element: any) => {
                return ![
                    "Top 10 families",
                    "Top 10 familles",
                    "Top 10 sub-families",
                    "Top 10 sous familles",
                    "Top 10 products",
                    "Top 10 Articles",
                ].includes(element?.label);
            })
            ?.map((el: any) => {
                if (el?._id !== undefined) {
                    return el?._id;
                }

                return el?.label;
            })
            .filter((el: unknown) => el !== undefined);

        if (
            options.includes("Top 10 families") ||
            options.includes("Top 10 familles")
        ) {
            setTop10FamiliesSelection!(
                encodeURI(
                    filteredSelectedValue.length === 0
                        ? options
                              .filter(
                                  (option: any) =>
                                      ![
                                          "Top 10 families",
                                          "Top 10 familles",
                                      ].includes(option)
                              )
                              .join(",")
                        : filteredSelectedValue.join(",")
                )
            );
        } else if (
            options.includes("Top 10 sub-families") ||
            options.includes("Top 10 sous familles")
        ) {
            setTop10SubFamiliesSelection!(
                encodeURI(
                    filteredSelectedValue.length === 0
                        ? options
                              .filter(
                                  (option: any) =>
                                      ![
                                          "Top 10 sub-families",
                                          "Top 10 sous familles",
                                      ].includes(option)
                              )
                              .join(",")
                        : filteredSelectedValue.join(",")
                )
            );
        } else if (
            options.includes("Top 10 products") ||
            options.includes("Top 10 Articles")
        ) {
            setTop10ProductsSelection!(
                encodeURI(
                    filteredSelectedValue.length === 0
                        ? options
                              .filter(
                                  (option: any) =>
                                      ![
                                          "Top 10 products",
                                          "Top 10 Articles",
                                      ].includes(option)
                              )
                              .join(",")
                        : filteredSelectedValue.join(",")
                )
            );
        } else {
            setEmployeesQueryString(
                filteredSelectedValue.length > 0
                    ? encodeURI(
                          filteredSelectedValue
                              .filter((el: string) => {
                                  return (
                                      el !== undefined &&
                                      ![
                                          "Tous les employés",
                                          "All employees",
                                      ].includes(el!)
                                  );
                              })
                              .join(",")
                      )
                    : ""
            );
        }
    }, [
        options,
        selectedValueState,
        t,
        setTop10FamiliesSelection,
        setTop10SubFamiliesSelection,
        setTop10ProductsSelection,
    ]);

    React.useEffect(() => {
        clearStore();
    }, []);

    return (
        <Select
            options={
                options.length > 0
                    ? options!.map((option: string) => ({
                          label: option,
                          value: option,
                      }))
                    : employees?.map((option: any) => {
                          if (option?.designation === t("All employees")) {
                              return {
                                  label: option.designation,
                                  value: option.designation,
                              };
                          }
                          return {
                              label: `${
                                  option.designation
                              }/${option.shop_name!}`,
                              value: `${
                                  option.designation
                              }/${option.shop_name!}`,
                              _id: `${option.code!}|${option.shop_id!}|${option.schema!}`,
                          };
                      })
            }
            value={selectedValueState}
            placeholder={`${t("Select")}...`}
            onChange={onSelectChange}
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isClearable={
                selectedValueState &&
                options!
                    .map((option: string) => ({
                        label: option,
                        value: option,
                    }))
                    .filter((el: any) =>
                        Object.values(selectedValueState).includes(el.label)
                    ).length === 0
            }
            components={{
                Option,
                ValueContainer,
            }}
            noOptionsMessage={() => t("no option found")}
            className="my-1"
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    minWidth: 240,
                    minHeight: 40,
                    height: 40,
                    margin: 1,
                    borderRadius: "5px",
                    padding: "0px",
                }),
                valueContainer: (base) => ({
                    ...base,
                    width: "100%",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }),
                option: (styles) => {
                    return {
                        ...styles,
                        color: "#495057",
                    };
                },
            }}
            theme={(theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "neutral10",
                },
            })}
        />
    );
}
