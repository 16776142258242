import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import slugify from "@sindresorhus/slugify";

import { getColorsArray } from "@helpers/general";

import LegendBadge from "./LegendBadge";

type LegendPopoverWrapperPropsType = {
    title: string;
    labels: string[];
};

export default function LegendPopoverWrapper({
    title,
    labels,
}: LegendPopoverWrapperPropsType) {
    const { t } = useTranslation();
    const [isPopoverOpened, setIsPopoverOpened] = React.useState(false);

    const buttonId = `button_${slugify(title)}`;

    function toggleOpenedPopover() {
        setIsPopoverOpened(!isPopoverOpened);
    }

    return (
        <div className="d-flex justify-content-center">
            <Button
                id={buttonId}
                type="button"
                color="secondary"
                outline
                onClick={toggleOpenedPopover}
                className="see-legend-button__clz px-2 py-1"
            >
                {t("See legend")}
            </Button>
            <Popover
                placement="top"
                isOpen={isPopoverOpened}
                target={buttonId}
                toggle={toggleOpenedPopover}
            >
                <PopoverHeader className="d-flex justify-content-between">
                    <p className="text-uppercase font-size-14 mb-0 ">{title}</p>
                    <button
                        type="button"
                        style={{ lineHeight: "0" }}
                        className="close ml-1"
                        onClick={toggleOpenedPopover}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </PopoverHeader>
                <PopoverBody>
                    {labels.map((label: string, index: number) => {
                        return (
                            <LegendBadge
                                key={`legend-badge__${slugify(label)}${index}`}
                                label={label}
                                color={getColorsArray(labels.length)[index]}
                            />
                        );
                    })}
                </PopoverBody>
            </Popover>
        </div>
    );
}
