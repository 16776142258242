import React from "react";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import { useDispatch, useSelector } from "react-redux";
import NProgress from "nprogress";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
} from "@aureskonnect/react-ui";

import { removeRegulation, resetRegulation } from "@store/actions";

import { ArrowLeftSvgIcon } from "@components/Common/SvgIcons";

import "./ThirdStep.css";

export default function ThirdStep({ previous, go }: any) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selectedRegulationList = useSelector(
        (state: any) => state.regulations
    );
    const groupName = useSelector((state: any) => state.groups);

    const [filteredRegulationList, setFilteredRegulationList] = React.useState(
        selectedRegulationList
    );

    function handleRemoveSelectedRegulation(item: string) {
        dispatch(removeRegulation(item));
        setFilteredRegulationList(
            filteredRegulationList.filter(
                (itemValue: string) => itemValue !== item
            )
        );
    }

    function handlePreviousStepAction() {
        previous();
        dispatch(resetRegulation());
    }

    function handleSearchInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        let filtredRegulations = selectedRegulationList!.filter((item: any) => {
            return item.toUpperCase().includes(e.target.value.toUpperCase());
        });
        setFilteredRegulationList!(filtredRegulations);
    }

    async function handleSaveButtonClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/regulations/regulation`;
        let savedData = {
            designation: groupName,
            groupment: filteredRegulationList,
        };

        try {
            NProgress.start();

            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        NProgress.done();

                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        go(0);
                        dispatch(resetRegulation());

                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                        });
                    })
            );
        } catch (e: any) {
            NProgress.done();

            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
            });
        }
    }

    return (
        <React.Fragment>
            <div className="d-md-flex justify-content-md-between">
                <div className="d-md-flex align-items-md-center my-2">
                    <div className="d-md-flex align-items-md-center">
                        <ArrowLeftSvgIcon
                            style={{
                                cursor: "pointer",
                            }}
                            className="ml-2"
                            onClick={handlePreviousStepAction}
                        />

                        <span className="ml-2 mb-0 font-size-14 h5">
                            {`${t("My selection")} (${
                                selectedRegulationList.length
                            })`}
                        </span>
                    </div>
                    <span
                        className="divider mt-0 mx-4 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />

                    <StyledSearchInput
                        onChange={handleSearchInputOnChangeEvent}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                    />
                </div>
                <StyledButton
                    className="border-0 mr-0 ml-2 my-2 w-xs-95 mx-md-2 w-sm-95 w-md-unset"
                    outline={false}
                    rounded
                    variant="success"
                    onClick={handleSaveButtonClickEvent}
                    disabled={filteredRegulationList.length === 0}
                >
                    {t("Save")}
                </StyledButton>
            </div>
            <Table responsive className="ml-3" style={{ width: "95%" }}>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("Designation")}</th>
                        <th> {t("Group")} </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filteredRegulationList.map(
                        (selectedMode: string, index: number) => (
                            <tr key={index}>
                                <th></th>
                                <td className="text-capitalize align-middle">
                                    {selectedMode}
                                </td>
                                <td className="align-middle">{t(groupName)}</td>

                                <td>
                                    <StyledButton
                                        outline
                                        rounded
                                        variant="secondary"
                                        style={{
                                            float: "right",
                                        }}
                                        className="d-none d-sm-none d-md-block"
                                        onClick={() =>
                                            handleRemoveSelectedRegulation(
                                                selectedMode
                                            )
                                        }
                                    >
                                        {t("Delete")}
                                    </StyledButton>
                                    <StyledIconButton
                                        className="d-block d-sm-block d-md-none m-0"
                                        icon="TrashIcon"
                                        onClick={() =>
                                            handleRemoveSelectedRegulation(
                                                selectedMode
                                            )
                                        }
                                    >
                                        <TrashIcon height={20} width={20} />
                                    </StyledIconButton>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>
        </React.Fragment>
    );
}
