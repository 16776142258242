import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import useResource from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";

import MiniCard from "@components/Common/MiniCard";

export default function Cards() {
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [families, setFamilies] = React.useState<string[]>([]);
    const [amounts, setAmounts] = React.useState<number[]>([]);

    const { resourceData: familiesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/families/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localfamilies: string[] = [];
        let localAmounts: number[] = [];

        familiesData.data?.forEach(
            (mode: { designation: string; value: number }) => {
                localfamilies.push(mode.designation);
                localAmounts.push(mode.value);
            }
        );

        setFamilies(localfamilies);
        setAmounts(localAmounts);
    }, [familiesData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <Row>
                    {families?.map((title: string, index: number) => {
                        return (
                            <Col key={`${title}_${index}`}>
                                <MiniCard
                                    title={title}
                                    amount={amounts[index]}
                                />
                            </Col>
                        );
                    })}
                </Row>
            )}
        </React.Fragment>
    );
}
