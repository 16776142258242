import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    DateRangePicker,
    defaultStaticRanges,
    createStaticRanges,
} from "@atefbena/react-date-range";
import i18n from "i18next";
import frLocale from "date-fns/locale/fr";
import enUsLocale from "date-fns/locale/en-US";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card } from "reactstrap";

import { getAdvancedSeeMorePeriod as getAdvancedPeriod } from "@helpers/general";

import {
    clearRegulationModes,
    clearSalesMode,
    clearSalesSupport,
    getAdvancedPeriodDate,
    changePeriod as changePeriodAction,
} from "@store/actions";

import "@atefbena/react-date-range/dist/styles.css";
import "@atefbena/react-date-range/dist/theme/default.css";

export default function DateRangePickerWrapper({
    toggleOpenedPopover,
}: {
    toggleOpenedPopover: Function;
}) {
    moment.locale(i18n.language);

    const { t } = useTranslation();
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const period = useSelector((state: any) => state.period);

    const [state, setState] = React.useState([
        {
            startDate: new Date(
                Number(getAdvancedPeriod(period, advancedDate).startDate) * 1000
            ),
            endDate: new Date(
                Number(getAdvancedPeriod(period, advancedDate).endDate) * 1000
            ),
            key: "selection",
            label: t("Current week"),
        },
    ]);

    let translatedDefaultStaticRanges: any[] = defaultStaticRanges
        .filter(
            (range: any) =>
                ![
                    "Last Year",
                    "Last Month",
                    "Last Week",
                    "This Week",
                    "This Month",
                ].includes(range.label)
        )
        .map(({ label, range, isSelected }: any) => ({
            label: t(label),
            range,
            isSelected,
        }));

    let staticRanges: any[] = createStaticRanges([
        ...translatedDefaultStaticRanges,
        {
            label: t("This Week"),
            range: () => ({
                startDate: moment().startOf("week").toDate(),
                endDate: moment().endOf("week").toDate(),
            }),
        },
        {
            label: t("This Month"),
            range: () => ({
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate(),
            }),
        },
        {
            label: t("This Year"),
            range: () => ({
                startDate: moment().startOf("year").toDate(),
                endDate: moment().endOf("year").toDate(),
            }),
        },
        {
            label: t("The last 7 days"),
            range: () => ({
                startDate: moment().subtract(7, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("The last 14 days"),
            range: () => ({
                startDate: moment().subtract(14, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("The last 30 days"),
            range: () => ({
                startDate: moment().subtract(30, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("The last 90 days"),
            range: () => ({
                startDate: moment().subtract(90, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("The last 180 days"),
            range: () => ({
                startDate: moment().subtract(180, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("The last 365 days"),
            range: () => ({
                startDate: moment().subtract(365, "days").toDate(),
                endDate: moment().subtract(1, "days").toDate(),
            }),
        },
        {
            label: t("Personalized"),
            range: () => ({
                startDate:
                    advancedDate.startDate !== ""
                        ? new Date(Number(advancedDate.startDate) * 1000)
                        : moment().subtract(1, "days").toDate(),
                endDate:
                    advancedDate.endDate !== ""
                        ? new Date(Number(advancedDate.endDate) * 1000)
                        : new Date(),
            }),
        },
    ]).filter((element: any) =>
        pathname === "/employees-delivery-man"
            ? element.label !== t("Today")
            : element
    );

    function handleOnChangeEvent(item: any) {
        setState([item.selection]);
    }

    function handleValidateButtonOnClickEvent() {
        let selectedRangeIndexInStaticRange = staticRanges.findIndex(
            (range: any) => {
                return (
                    moment(range.range().startDate).format("DD/MM/YYYY") ===
                        moment(state[0].startDate).format("DD/MM/YYYY") &&
                    moment(range.range().endDate).format("DD/MM/YYYY") ===
                        moment(state[0].endDate).format("DD/MM/YYYY")
                );
            }
        );

        const PERSONALIZED_RANGE_INDEX_IN_STATIC_RANGE = 11;

        if (
            selectedRangeIndexInStaticRange === -1 ||
            selectedRangeIndexInStaticRange ===
                PERSONALIZED_RANGE_INDEX_IN_STATIC_RANGE
        ) {
            dispatch(
                getAdvancedPeriodDate({
                    startDate: String(moment(state[0].startDate).unix()),
                    endDate: String(moment(state[0].endDate).unix()),
                })
            );
            dispatch(changePeriodAction(t("Personalized")));
        } else {
            dispatch(
                getAdvancedPeriodDate({
                    startDate: "",
                    endDate: "",
                })
            );
            dispatch(
                changePeriodAction(
                    staticRanges[selectedRangeIndexInStaticRange]?.label
                )
            );
        }

        dispatch(clearSalesSupport());
        dispatch(clearRegulationModes());
        dispatch(clearSalesMode());
        toggleOpenedPopover();
    }

    return (
        <Card
            style={{ boxShadow: "0px 11px 14px 7px rgba(0, 0, 0, 0.11)" }}
            className="pb-1"
        >
            <DateRangePicker
                onChange={handleOnChangeEvent}
                months={1}
                ranges={state}
                direction="horizontal"
                maxDate={
                    pathname === "/employees-delivery-man"
                        ? new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
                        : new Date()
                }
                weekStartsOn={1}
                locale={i18n.language === "fr" ? frLocale : enUsLocale}
                dateDisplayFormat={"dd MMM yyyy"}
                staticRanges={staticRanges}
                className="input-ranges-display-none__clz"
            />
            <div className="d-flex justify-content-end mb-2">
                <Button
                    type="button"
                    color="dark"
                    className="ml-3"
                    outline
                    onClick={() => toggleOpenedPopover()}
                >
                    {t("Cancel")}
                </Button>
                <Button
                    type="button"
                    color="dark"
                    onClick={handleValidateButtonOnClickEvent}
                    className="mx-3"
                >
                    {t("Validate")}
                </Button>
            </div>
        </Card>
    );
}
