import React from "react";
import { CardBody, Card, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

export default function StatisticsTop10EmployeesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [top10EmployeesData, setTop10EmployeesData] = React.useState<any[]>(
        []
    );

    const {
        resourceData: statisticsEmployeesData,
        error: statisticsEmployeesError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/cashier/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsEmployeesData.data);
        setTop10EmployeesData(
            getStatisticsData(
                statisticsEmployeesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsEmployeesData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right">
                    <Link
                        to="/turnover-detail"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <div className="apex-charts">
                    {statisticsEmployeesError ? (
                        <ErrorPage />
                    ) : (
                        <ChartWrapper
                            series={top10EmployeesData}
                            categories={categories}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
