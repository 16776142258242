import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useResource from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

import Orders from "../Orders";

export default function OrdersWrapper() {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [totalShopOrderAmount, setShopOrderAmount] = React.useState<number>(
        0
    );
    const [shopOrderPercentage, setShopOrderPercentage] = React.useState<
        number
    >(0);
    const [totalOnlineOrderAmount, setOnlineOrderAmount] = React.useState<
        number
    >(0);
    const [onlineOrderPercentage, setOnlineOrderPercentage] = React.useState<
        number
    >(0);
    const [
        shopOrderChartPercentage,
        setShopOrderChartPercentage,
    ] = React.useState<number>(0);
    const [
        onlineOrderChartPercentage,
        setOnlineOrderChartPercentage,
    ] = React.useState<number>(0);

    const { resourceData: shopOrder, error: shopOrderError } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/shop-orders?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    const { resourceData: onlineOrder, error: onlineOrderError } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/online-orders?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        if (shopOrder.data) {
            let localAmount: number = 0;
            let localPercentage: number = 0;

            if (Array.isArray(shopOrder?.data)) {
                shopOrder.data?.forEach((item: any) => {
                    localAmount = item.value;
                    localPercentage = item.percentage;
                });
            } else {
                localAmount = shopOrder?.data.value;
                localPercentage = shopOrder?.data.percentage;
            }

            setShopOrderAmount(localAmount);
            setShopOrderPercentage(localPercentage);
        }

        if (onlineOrder.data) {
            let localAmount: number = 0;
            let localPercentage: number = 0;

            if (Array.isArray(onlineOrder?.data)) {
                onlineOrder?.data.forEach((item: any) => {
                    localAmount = item.value;
                    localPercentage = item.percentage;
                });
            } else {
                localAmount = onlineOrder?.data.value;
                localPercentage = onlineOrder?.data.percentage;
            }

            setOnlineOrderAmount(localAmount);
            setOnlineOrderPercentage(localPercentage);
        }

        let total = totalShopOrderAmount + totalOnlineOrderAmount;
        setShopOrderChartPercentage(
            total === 0 ? 0 : (totalShopOrderAmount / total) * 100
        );
        setOnlineOrderChartPercentage(
            total === 0 ? 0 : (totalOnlineOrderAmount / total) * 100
        );
    }, [
        t,
        type,
        shopOrder,
        onlineOrder,
        totalShopOrderAmount,
        totalOnlineOrderAmount,
        shopOrderChartPercentage,
        onlineOrderChartPercentage,
    ]);

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {shopOrderError ? (
                <ErrorPage />
            ) : (
                <Orders
                    title={t("Shop orders").toUpperCase()}
                    totalAmount={totalShopOrderAmount}
                    totalPercentage={shopOrderPercentage}
                    chartPercentage={shopOrderChartPercentage}
                />
            )}
            {onlineOrderError ? (
                <ErrorPage />
            ) : (
                <Orders
                    title={t("Online orders").toUpperCase()}
                    totalAmount={totalOnlineOrderAmount}
                    totalPercentage={onlineOrderPercentage}
                    chartPercentage={onlineOrderChartPercentage}
                />
            )}
        </React.Suspense>
    );
}
