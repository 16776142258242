import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import useResource from "@hooks/useResource";

import {
    capitalize,
    getAdvancedPeriodStringParam,
    getTypeParam,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import GroupedCustomSelect from "@components/Common/GroupedCustomSelect";

export default function SelectFilter() {
    const { t } = useTranslation();
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const [salesSupport, setSalesSupport] = React.useState<string[]>([
        t("All sales supports"),
    ]);
    const [amounts, setAmounts] = React.useState<number[]>([]);
    const [selectGroupOptions, setSelectGroupOptions] = React.useState<
        SelectFilterType[]
    >([
        {
            label: t("All sales supports"),
            options: [t("All sales supports")],
        },
    ]);

    const { resourceData: salesSupportData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-support/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSalesSupport: string[] = [];
        let localAmounts: number[] = [];
        let localOptions: string[] = [];

        let array: SelectFilterType[] = [];

        salesSupportData.data?.forEach(
            (mode: {
                designation: string;
                value: number;
                data: { designation: string; value: number }[];
            }) => {
                if (mode.designation === "All sales supports") {
                    localSalesSupport.push(mode.designation);
                    localAmounts.push(mode.value);
                } else {
                    localSalesSupport.push(mode.designation);
                    localAmounts.push(mode.value);

                    if (mode.designation === "click_&_collect") {
                        localOptions = [];
                        mode?.data.forEach(
                            (element: {
                                designation: string;
                                value: number;
                            }) => {
                                localSalesSupport.push(element.designation);
                                localAmounts.push(element.value);
                                localOptions.push(
                                    t(capitalize(element.designation))
                                );
                            }
                        );

                        array.push({
                            label: mode.designation.replaceAll("_", " "),
                            options: localOptions,
                        });
                    } else if (mode.designation === "shop") {
                        localOptions = [];
                        mode?.data.forEach(
                            (element: {
                                designation: string;
                                value: number;
                            }) => {
                                localSalesSupport.push(element.designation);
                                localAmounts.push(element.value);
                                localOptions.push(
                                    t(capitalize(element.designation))
                                );
                            }
                        );

                        array.push({
                            label: mode.designation,
                            options: localOptions,
                        });
                    } else if (mode.designation === "platform") {
                        localOptions = [];
                        mode?.data.forEach(
                            (element: {
                                designation: string;
                                value: number;
                            }) => {
                                localSalesSupport.push(element.designation);
                                localAmounts.push(element.value);
                                localOptions.push(
                                    t(capitalize(element.designation))
                                );
                            }
                        );

                        array.push({
                            label: mode.designation,
                            options: localOptions,
                        });
                    }
                }
            }
        );

        setSelectGroupOptions([
            ...[
                {
                    label: t("All sales supports"),
                    options: [t("All sales supports")],
                },
            ],
            ...array,
        ]);

        setSalesSupport(
            localSalesSupport.map((el: string) => t(capitalize(el)))
        );
        setAmounts(localAmounts);
    }, [salesSupportData, t]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className="dark-bg__clz">
                        <CardBody className="py-1 px-3">
                            {error ? (
                                <ErrorPage />
                            ) : (
                                <CustomErrorBoundary>
                                    <Row className="d-flex align-items-center">
                                        <div
                                            style={{
                                                minWidth: "18%",
                                                paddingLeft: "12px",
                                            }}
                                        >
                                            <CardTitle
                                                tag="h4"
                                                className="text-white"
                                            >
                                                {t("Select your sales support")}
                                            </CardTitle>
                                        </div>
                                        <Col
                                            xs="10"
                                            sm="6"
                                            md="5"
                                            lg="5"
                                            xl="4"
                                        >
                                            <GroupedCustomSelect
                                                options={selectGroupOptions}
                                                amounts={amounts}
                                                allModes={salesSupport}
                                            />
                                        </Col>
                                    </Row>
                                </CustomErrorBoundary>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}
