import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import {
    AddIcon,
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
} from "@aureskonnect/react-ui";

import store, { setGroupedSubFamilies } from "./store";

import useResource from "@hooks/useResource";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import Pagination from "@components/Common/StatisticsPagination";
import NoImagePlaceholderIcon from "../components/NoImagePlaceholderIcon";

export default function SecondStep({ go }: any) {
    const { t } = useTranslation();
    const { groupedSubFamilies, groupName } = useSnapshot(store);

    const ITEMS_PER_PAGE = 5;

    const [subFamilies, setSubFamilies] = React.useState<any[]>([]);
    const [filteredSubFamilies, setFilteredSubFamilies] = React.useState<any[]>(
        []
    );
    const [selectedSubFamilies, setSelectedSubFamilies] = React.useState<any[]>(
        []
    );

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/sub-families`
    );

    React.useEffect(() => {
        let localSubFamilies: any[] = resourceData.data!.filter(
            (family: any) => {
                return !groupedSubFamilies
                    .map(
                        (subFamilyGroup: any) =>
                            `${subFamilyGroup.sub_family}|${subFamilyGroup.family}`
                    )
                    .includes(`${family.sub_family}|${family.family}`);
            }
        );
        setSubFamilies(localSubFamilies);
        setFilteredSubFamilies(localSubFamilies);
    }, [resourceData, groupedSubFamilies]);

    const handleAddingSubFamilyButtonOnClickEvent = React.useCallback(
        (subFamily: any) => {
            let localSelectedSubFamilies: any[] = [
                ...selectedSubFamilies,
                subFamily,
            ];
            setSelectedSubFamilies(localSelectedSubFamilies);
            let localSubFamilies: any[] = (filteredSubFamilies.length > 0
                ? filteredSubFamilies
                : subFamilies
            ).filter((subFamily) => {
                return !localSelectedSubFamilies
                    .map((selectedSubFamily) =>
                        `${selectedSubFamily.sub_family}|${selectedSubFamily.family}`.toLowerCase()
                    )
                    .includes(
                        `${subFamily.sub_family}|${subFamily.family}`.toLowerCase()
                    );
            });

            setFilteredSubFamilies(localSubFamilies);
        },
        [selectedSubFamilies, filteredSubFamilies, subFamilies]
    );
    const columns = React.useMemo(
        () => [
            {
                Header: t("Subfamily"),
                accessor: "sub_family",
            },
            {
                Header: t("Picture"),
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            {subFamily.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                subFamily.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Family"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                style={{
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block"
                                onClick={() =>
                                    handleAddingSubFamilyButtonOnClickEvent(
                                        subFamily
                                    )
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="PlusIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleAddingSubFamilyButtonOnClickEvent(
                                        subFamily
                                    )
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingSubFamilyButtonOnClickEvent]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredSubFamilies,

            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    function handleMultipleAddingSubFamiliesButtonOnClickEvent() {
        let localSelectedSubFamilies: any[] = [
            ...selectedSubFamilies,
            ...selectedFlatRows.map((row: any) => row.original),
        ];
        setSelectedSubFamilies(localSelectedSubFamilies);
        let localSubFamilies: any[] = filteredSubFamilies.filter(
            (subFamily) => {
                return !localSelectedSubFamilies
                    .map((s) => `${s.sub_family}|${s.family}`.toLowerCase())
                    .includes(
                        `${subFamily.sub_family}|${subFamily.family}`.toLowerCase()
                    );
            }
        );
        setFilteredSubFamilies(localSubFamilies);
    }

    function handleSelectionListButtonOnClickEvent() {
        setGroupedSubFamilies([...groupedSubFamilies, ...selectedSubFamilies]);
        go(3);
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between">
                        <div className="d-md-flex align-items-md-center my-2">
                            <span className="mb-0 ml-2 mr-0 h5 white-space-nowrap">
                                {groupName}
                            </span>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleAddingSubFamiliesButtonOnClickEvent
                                }
                            >
                                {t("Add sub-families")}
                            </StyledButton>
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                disabled={
                                    selectedSubFamilies.length +
                                        groupedSubFamilies.length ===
                                    0
                                }
                                onClick={handleSelectionListButtonOnClickEvent}
                            >
                                {`${t("My selection")} (${
                                    selectedSubFamilies.length +
                                    groupedSubFamilies.length
                                })`}
                            </StyledButton>
                        </div>
                    </div>
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {filteredSubFamilies.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
