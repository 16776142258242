import React from "react";
import { Alert, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { mutate } from "swr";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import BTable from "react-bootstrap/Table";
import { AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";
import NProgress from "nprogress";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    StyledTextInput,
    TrashIcon,
    StyledParagraph,
    EditIcon,
} from "@aureskonnect/react-ui";

import { setGroupName, setGroupedArticles } from "./store";

import useResource from "@hooks/useResource";

import { SettingsArticlesContext } from "./context";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import Pagination from "@components/Common/StatisticsPagination";

import "@assets/widths.css";

export default function FirstStep({ next, go }: any) {
    const { t } = useTranslation();

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [groupDesignation, setGroupDesignation] = React.useState<string>("");
    const [groupDesignationList, setGroupDesignationList] = React.useState<
        string[]
    >([]);
    const [articlesGroups, setArticlesGroups] = React.useState<any>([]);

    const [isGroupNameEmpty, setIsGroupNameEmpty] = React.useState<boolean>(
        false
    );
    const { setGroups } = React.useContext(SettingsArticlesContext);
    const [
        isGroupNameContainsSpecialStrings,
        setIsGroupNameContainsSpecialStrings,
    ] = React.useState<boolean>(false);

    const [
        isNameExistsInGroupsList,
        setIsNameExistsInGroupsList,
    ] = React.useState<boolean>(false);

    const { resourceData: groupsData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/products/groups`
    );

    React.useEffect(() => {
        setArticlesGroups(groupsData.data!.concat(groupDesignationList));
        setGroupedArticles([]);
        setGroups([...groupDesignationList, ...groupsData!.data]);
    }, [groupsData, groupDesignationList, setGroups]);

    const handleEditGroupButtonOnClickEvent = React.useCallback(
        (groupName: string) => {
            setGroupName(groupName);
            go(2);
        },
        [go]
    );

    const handleRemoveGroupButtonOnClickEvent = React.useCallback(
        async (group: string) => {
            swal({
                text: t("Are you sure you want to delete this group?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    setArticlesGroups(
                        articlesGroups.filter((item: string) => {
                            return item !== group;
                        })
                    );

                    try {
                        NProgress.start();

                        mutate(
                            `${process.env.REACT_APP_API_V1_URL}/settings/products/group`,
                            await fetch(
                                `${process.env.REACT_APP_API_V1_URL}/settings/products/group`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    method: "DELETE",
                                    body: JSON.stringify({
                                        designation: group,
                                    }),
                                }
                            )
                                .then((response) => response.json())
                                .then(async (data) => {
                                    NProgress.done();
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                    return swal({
                                        icon: "success",
                                        content: (
                                            <p>{t(data.message)}​​​​​​​​!</p>
                                        ),
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}​​​​​​​​</p>,
                        });
                    }
                }
            });
        },
        [articlesGroups, t]
    );

    function handleValidateGroupDesignationButtonOnClickEvent() {
        if (groupDesignation.length === 0) {
            setIsGroupNameEmpty(true);
        } else {
            setIsGroupNameEmpty(false);
            toggleOpeningModal();
            setGroupDesignationList([
                ...groupDesignationList,
                groupDesignation,
            ]);
            setGroupDesignation("");
            setGroupName(groupDesignation);
            next();
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("Article groups"),
                accessor: function (data: any) {
                    return data;
                },
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (group: any) {
                    return (
                        <div className="d-flex justify-content-end">
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                className="px-2 mr-2 py-1 d-none d-sm-none d-md-block"
                                disabled={groupDesignationList.includes(group)}
                                onClick={() =>
                                    handleEditGroupButtonOnClickEvent(group)
                                }
                            >
                                {t("Edit")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none"
                                icon="EditIcon"
                                disabled={groupDesignationList.includes(group)}
                                onClick={() =>
                                    handleEditGroupButtonOnClickEvent(group)
                                }
                            >
                                <EditIcon height={20} width={20} />
                            </StyledIconButton>
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                className="px-2 py-1 d-none d-sm-none d-md-block"
                                disabled={groupDesignationList.includes(group)}
                                onClick={() =>
                                    handleRemoveGroupButtonOnClickEvent(group)
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none"
                                icon="TrashIcon"
                                disabled={groupDesignationList.includes(group)}
                                onClick={() =>
                                    handleRemoveGroupButtonOnClickEvent(group)
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [
            t,
            handleEditGroupButtonOnClickEvent,
            handleRemoveGroupButtonOnClickEvent,
            groupDesignationList,
        ]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: articlesGroups,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    function toggleOpeningModal() {
        setIsModalOpened(!isModalOpened);
        setIsGroupNameEmpty(false);
        setGroupDesignation("");
    }

    function handleGroupDesignationInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setGroupDesignation(e.target.value);
        let specialStrings: string[] = [
            "\\",
            "+",
            "-",
            "&",
            "/",
            "*",
            "!",
            "?",
            "ç",
            "#",
            "~",
            "^",
            "@",
            '"',
            "]",
            "}",
            "#",
            "{",
            "[",
            "|",
            "-",
            "'",
        ];

        setIsGroupNameContainsSpecialStrings(
            specialStrings.filter((string: any) => {
                return e.target.value.includes(string);
            }).length > 0
        );
        setIsGroupNameEmpty(
            e.target.value.length === 0 || e.target.value.trim().length === 0
        );

        setIsNameExistsInGroupsList(
            articlesGroups.filter(
                (group: any) => group === e.target.value.trim()
            ).length > 0
        );
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div
                        className={classNames({
                            "d-md-flex justify-content-md-between":
                                articlesGroups.length > 0,
                            "d-flex justify-content-end":
                                articlesGroups.length === 0,
                        })}
                    >
                        {articlesGroups.length !== 0 ? (
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="w-xs-90 m-2 p-0"
                            />
                        ) : null}
                        <StyledButton
                            rounded
                            variant="primary"
                            className="my-2 mx-2 px-0 px-md-3 w-xs-95 w-sm-95  white-space-nowrap"
                            onClick={toggleOpeningModal}
                        >
                            {t("create a group")}
                        </StyledButton>
                    </div>
                    {articlesGroups.length !== 0 ? (
                        <React.Fragment>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {articlesGroups.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}

                    <Modal isOpen={isModalOpened} centered>
                        <ModalBody className="justify-content-center">
                            <StyledParagraph className="text-uppercase font-weight-bold text-center mt-4">
                                {t("new article(s) group")}
                            </StyledParagraph>
                            {isGroupNameEmpty ? (
                                <Alert className="mx-4" color="danger">
                                    {t("Please enter the name of the group")}
                                </Alert>
                            ) : null}
                            {isGroupNameContainsSpecialStrings ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t(
                                        "The name should not contain any special characters"
                                    )}
                                </Alert>
                            ) : null}
                            {isNameExistsInGroupsList ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t("This name is used before")}
                                </Alert>
                            ) : null}
                            <AvForm>
                                <StyledTextInput
                                    id="articles-group-designation"
                                    name="articles-group-designation"
                                    value={groupDesignation}
                                    placeholder={t("Enter the designation")}
                                    type="text"
                                    className="mt-5 ml-4"
                                    style={{ width: "90%" }}
                                    onChange={
                                        handleGroupDesignationInputOnChangeEvent
                                    }
                                />
                            </AvForm>
                        </ModalBody>
                        <ModalFooter className="justify-content-center mt-3">
                            <StyledButton
                                variant="primary"
                                outline
                                rounded
                                onClick={toggleOpeningModal}
                                className="w-25"
                            >
                                {t("Return")}
                            </StyledButton>
                            <StyledButton
                                variant="primary"
                                outline={false}
                                rounded
                                onClick={
                                    handleValidateGroupDesignationButtonOnClickEvent
                                }
                                disabled={
                                    isGroupNameContainsSpecialStrings ||
                                    isNameExistsInGroupsList ||
                                    isGroupNameEmpty
                                }
                                className="w-25"
                            >
                                {t("Validate")}
                            </StyledButton>
                        </ModalFooter>
                    </Modal>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
