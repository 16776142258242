import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import slugify from "@sindresorhus/slugify";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-duration-format";

import { getRandomInt, getColorsArray, formatCurrency } from "@helpers/general";

import "./index.css";

type DonutChartPropsType = {
    data: SeriesDataType;
    title: string;
    children?: React.ReactNode;
};

export default function DonutChart({
    data,
    title,
    children,
}: DonutChartPropsType) {
    const { t } = useTranslation();

    const type = useSelector((state: any) => state.type);

    const series: any[] = data.data;

    const options: object = {
        labels: data.labels,
        tooltip: {
            enabled: true,
            shared: true,
            y: {
                formatter: function (val: any) {
                    return [
                        t("Tickets"),
                        t("Quantity sold"),
                        t("Item number"),
                        t("Quantity"),
                    ].includes(type)
                        ? val
                        : [t("Duration")].includes(type)
                        ? moment
                              .duration(val, "h")
                              .format(
                                  `y [${t("y")}] M [${t("m")}] w [${t(
                                      "w"
                                  )}] D [${t("d")}] H [${t("h")}] m [${t(
                                      "min"
                                  )}] s [${t("sec")}]`,
                                  { trim: "all" }
                              )
                        : formatCurrency(val);
                },
            },
        },
        colors: getColorsArray(series.length),
        legend: {
            show: false,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 1300,
                options: {
                    chart: {
                        height: 280,
                    },
                },
            },
            {
                breakpoint: 1175,
                options: {
                    chart: {
                        height: 265,
                    },
                },
            },
            {
                breakpoint: 1140,
                options: {
                    chart: {
                        height: 255,
                    },
                },
            },
            {
                breakpoint: 1110,
                options: {
                    chart: {
                        height: 230,
                    },
                },
            },
            {
                breakpoint: 1075,
                options: {
                    chart: {
                        height: 235,
                    },
                },
            },
            {
                breakpoint: 1050,
                options: {
                    chart: {
                        height: 230,
                    },
                },
            },
            {
                breakpoint: 1030,
                options: {
                    chart: {
                        height: 217,
                    },
                },
            },
            {
                breakpoint: 1000,
                options: {
                    chart: {
                        height: 214,
                    },
                },
            },
            {
                breakpoint: 993,
                options: {
                    chart: {
                        height: 257,
                    },
                },
            },
            {
                breakpoint: 870,
                options: {
                    chart: {
                        height: 250,
                    },
                },
            },
            {
                breakpoint: 850,
                options: {
                    chart: {
                        height: 245,
                    },
                },
            },
            {
                breakpoint: 830,
                options: {
                    chart: {
                        height: 235,
                    },
                },
            },
            {
                breakpoint: 780,
                options: {
                    chart: {
                        height: 215,
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    chart: {
                        height: 300,
                    },
                },
            },
            {
                breakpoint: 355,
                options: {
                    chart: {
                        height: 290,
                    },
                },
            },
            {
                breakpoint: 320,
                options: {
                    chart: {
                        height: 280,
                    },
                },
            },
            {
                breakpoint: 301,
                options: {
                    chart: {
                        height: 250,
                    },
                },
            },
            {
                breakpoint: 290,
                options: {
                    chart: {
                        height: 230,
                    },
                },
            },
        ],
    };

    return (
        <Card>
            <CardBody>
                <div className="p-3">
                    <div className="apex-charts">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height="308"
                            key={`apex-chart__${getRandomInt(
                                series.length + Math.random()
                            )}_${slugify(`${title}_${type}`)}_${Date.now()}`}
                        />
                    </div>
                    {children}
                </div>
            </CardBody>
        </Card>
    );
}
