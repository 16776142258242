import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    getStringsArrayUniqueValues,
    getTypeParam,
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
} from "@helpers/general";
import useResource from "@hooks/useResource";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import ErrorPage from "@components/Common/ErrorPage";

import DonutChart from "../DonutChart";

export default function SalesSupportRow({
    type,
    period,
    designation,
}: StatisticsChartsRowsPropsType) {
    const { t } = useTranslation();
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            designation && designation !== "my_favorites"
                ? `&selected=${designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        resourceData.data?.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
        });

        localData = getRoundedArrayNumbers(localData);
        setData(localData?.filter((item) => item !== 0));
        setLabels(
            getStringsArrayUniqueValues(localLabels).filter(
                (item) => item !== ""
            )
        );
    }, [resourceData]);

    const salesSupportInitData: SeriesDataType = {
        data,
        labels:
            labels?.length > 0
                ? labels?.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            {error ? (
                <ErrorPage />
            ) : (
                <DonutChart
                    title={t("Sales support")}
                    data={salesSupportInitData}
                    type={type}
                />
            )}
        </React.Suspense>
    );
}
