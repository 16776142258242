import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import useResource from "@hooks/useResource";

import {
    capitalize,
    getAdvancedPeriodStringParam,
    getTypeParam,
} from "@helpers/general";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import CustomSelectGroup from "@components/Common/CustomSelectGroup";

export default function SelectFilter() {
    const { t } = useTranslation();
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const [salesModes, setSalesModes] = React.useState<string[]>([
        t("All sales methods"),
    ]);
    const [amounts, setAmounts] = React.useState<number[]>([]);

    const { resourceData: salesModesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSalesModes: string[] = [];
        let localAmounts: number[] = [];
        salesModesData.data?.forEach(
            (mode: { designation: string; value: number }) => {
                localSalesModes.push(mode.designation);
                localAmounts.push(mode.value);
            }
        );

        setSalesModes(localSalesModes.map((el: string) => t(capitalize(el))));
        setAmounts(localAmounts);
    }, [salesModesData, t]);

    return (
        <React.Fragment>
            {salesModes.length > 0 ? (
                <Row>
                    <Col>
                        <Card className="dark-bg__clz">
                            <CardBody className="py-1 px-3">
                                {error ? (
                                    <ErrorPage />
                                ) : (
                                    <CustomErrorBoundary>
                                        <div className="d-flex align-items-center">
                                            <CardTitle
                                                tag="h4"
                                                className="card-title mb-0 mr-3 text-white"
                                            >
                                                {t("Select your sales modes")}
                                            </CardTitle>
                                            <CustomSelectGroup
                                                options={salesModes}
                                                amounts={amounts}
                                            />
                                        </div>
                                    </CustomErrorBoundary>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : null}
        </React.Fragment>
    );
}
