import React from "react";

export default function WebsiteSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8780">
                <g data-name="Groupe 8758">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g fill="#fff">
                    <path
                        data-name="Trac\xE9 8568"
                        d="M18.766 12.26l18.193-.077v12.322l-10.02.042v2.217h10.1a2.185 2.185 0 002.183-2.183v-12.4a2.185 2.185 0 00-2.183-2.183H18.766a2.185 2.185 0 00-2.183 2.183v3.881h2.207z"
                    />
                    <path
                        data-name="Trac\xE9 8569"
                        d="M40.403 27.288H26.939v2.582h12.308a1.869 1.869 0 001.868-1.869.713.713 0 00-.712-.713z"
                    />
                    <path
                        data-name="Trac\xE9 8570"
                        d="M22.894 17.759h-7.937a2.352 2.352 0 00-2.349 2.35v3.267h1.288a2.807 2.807 0 01.972.183V20.11a.089.089 0 01.089-.089h7.937a.089.089 0 01.089.089v11.015h-6.26v3.195h6.171a2.352 2.352 0 002.35-2.35V20.109a2.352 2.352 0 00-2.35-2.35zm-3.968 15.528a.732.732 0 01-.65-.407.721.721 0 01-.088-.332.738.738 0 011.477 0 .72.72 0 01-.088.332.732.732 0 01-.651.407z"
                    />
                    <path
                        data-name="Trac\xE9 8571"
                        d="M13.9 24.787H8.415A1.417 1.417 0 007 26.202v10.382a1.416 1.416 0 001.415 1.415H13.9a1.416 1.416 0 001.415-1.415V26.202a1.417 1.417 0 00-1.415-1.415zm-5.2 1.7h4.916v8.975H8.7zm2.461 10.83a.608.608 0 11.4-.16.592.592 0 01-.405.155z"
                    />
                </g>
            </g>
        </svg>
    );
}
