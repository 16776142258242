import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { useHistory, useLocation } from "react-router-dom";

import store from "@pages/Statistics/store";
import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getAdvancedSeeMorePeriod,
    getFormattedCategoriesByPeriod,
    getRegulationModesQueryString,
    getStatisticsData,
    getTypeParam,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsSalesSupportCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [salesSupportsData, setSalesSupportsData] = React.useState<
        SeriesDataType[]
    >([]);

    const {
        resourceData: statisticsSalesSupportData,
        error: statisticsSalesSupportDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getRegulationModesQueryString(
                statisticsStore.regulationModesCards.modes
            ).length > 0
                ? `&regulation=${getRegulationModesQueryString(
                      statisticsStore.regulationModesCards.modes
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsSalesSupportData.data);
        setSalesSupportsData(
            getStatisticsData(
                statisticsSalesSupportData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsSalesSupportData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "regulations",
                    value: [
                        ...statisticsStore.regulationModesCards.modes
                            .filter(
                                (mode: string) =>
                                    mode !== t("All regulation methods")
                            )
                            .map(getUntranslatedString),
                    ],
                },
            ],
        };

        setAdvancedLegend(t("Sales support"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm card-body__clz-md">
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsSalesSupportDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={salesSupportsData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
