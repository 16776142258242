import React from "react";
import { useSelector } from "react-redux";

import useResource from "@hooks/useResource";

import { formatCurrency, getAdvancedPeriodStringParam } from "@helpers/general";

import { setTransactionsAmountRange } from "../../store";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import { StyledRange } from "../../AverageBasket/ReactSliderWrapper/StyledRange";

export default function ReactSliderWrapper() {
    const period = useSelector((state: any) => state.period);
    const advancedDate = useSelector(
        (state: AdvancedDateStateType) => state.advancedDate
    );
    const selections = useSelector((state: any) => state.selections);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/transactions/transaction-amount?${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }&${getAdvancedPeriodStringParam(period, advancedDate)}`
    );

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <StyledRange
                        defaultValue={[
                            Math.trunc(resourceData.data.min),
                            Math.round(resourceData.data.max),
                        ]}
                        min={Math.trunc(resourceData.data.min)}
                        max={Math.round(resourceData.data.max)}
                        step={1}
                        tipFormatter={(value: any) => formatCurrency(value, 0)}
                        onChange={(range: any) => {
                            setTransactionsAmountRange(range);
                        }}
                    />
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
