import React from "react";
import { Button, Card, CardBody, Nav } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { useHistory, useLocation } from "react-router-dom";

import store from "@pages/Statistics/store";

import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getTypeParam,
    getSalesModesQueryString,
    getUntranslatedString,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getAdvancedSeeMorePeriod,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function RecapCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [salesModesData, setSalesModesData] = React.useState<
        SeriesDataType[]
    >([]);

    const statisticsStore = useSnapshot(store);

    const {
        resourceData: salesModeRecapData,
        error: salesModeRecapDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesModesQueryString(statisticsStore.salesModeCards.modes)
                .length > 0
                ? `&sales-mode=${getSalesModesQueryString(
                      statisticsStore.salesModeCards.modes.map(
                          getUntranslatedString
                      )
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(salesModeRecapData.data);
        setSalesModesData(
            getStatisticsData(
                salesModeRecapData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        salesModeRecapData,
        categories,
        period,
        advancedDate,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "sales_mode",
                    value: [
                        ...statisticsStore.salesModeCards.modes
                            .filter(
                                (mode: string) =>
                                    mode !== t("All sales methods")
                            )
                            .map(getUntranslatedString),
                    ],
                },
            ],
        };

        setAdvancedLegend(t("Sales mode"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody className="card-body__clz">
                <Nav
                    pills
                    className="navtab-bg nav__clz d-none"
                    style={{ float: "right" }}
                >
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div
                    className="apex-chart-padding-recap__clz apex-charts"
                    style={{ marginTop: "1.8rem" }}
                >
                    {salesModeRecapDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={salesModesData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
