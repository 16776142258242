import React from "react";

export default function OnTheSpotSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8791">
                <g data-name="Groupe 8786">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g fill="#fff">
                    <path
                        data-name="Rectangle 4165"
                        d="M14.64 14.355h17.754v1.396H14.64z"
                    />
                    <path
                        data-name="Trac\xE9 8593"
                        d="M37.26 14.355H9.774A.774.774 0 019 13.58v-1.806A.774.774 0 019.774 11H37.26a.774.774 0 01.774.774v1.806a.774.774 0 01-.774.775z"
                    />
                    <path
                        data-name="Rectangle 4166"
                        d="M21.767 14.355h3.5v18.273h-3.5z"
                    />
                    <path
                        data-name="Trac\xE9 8594"
                        d="M16.375 35.336l5.392-2.71h3.5l5.392 2.71v1.942H16.375z"
                    />
                </g>
            </g>
        </svg>
    );
}
