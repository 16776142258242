import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import {
    StyledButton,
    StyledIconButton,
    TrashIcon,
} from "@aureskonnect/react-ui";

import store, {
    clearStore,
    setSelectedSegments,
} from "@pages/Statistics/Transactions/store";

import ModalWrapper from "./ModalWrapper";
import SelectFilter from "../SelectFilter";

export default function Header() {
    const { t } = useTranslation();

    const { selectedSegments } = useSnapshot(store);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [segments, setSegments] = React.useState<string[]>([]);

    function toggleNewSegmentModal() {
        setIsModalOpened(!isModalOpened);
        clearStore();
    }

    const toggleNav = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    React.useEffect(() => {
        setSegments(selectedSegments);
    }, [selectedSegments]);

    async function handleRemoveSegmentButtonOnClickEvent(segment: string) {
        setSegments(segments.filter((s: string) => s !== segment));
        setSelectedSegments(segment);
    }

    return (
        <React.Fragment>
            <div className="d-xl-flex justify-content-xl-between">
                <div className="d-md-flex mb-0 mb-md-2 mb-xl-0">
                    {segments.map((segment) => {
                        return (
                            <div
                                key={uid(segment)}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                }}
                                className="mr-2 d-flex justify-content-between align-items-center w-100 rounded mb-2 mb-md-0"
                            >
                                <span
                                    className="white-space-nowrap"
                                    style={{ padding: "10px" }}
                                >
                                    {segment}
                                </span>
                                <StyledIconButton
                                    icon="TrashIcon"
                                    className="m-0"
                                    onClick={() =>
                                        handleRemoveSegmentButtonOnClickEvent(
                                            segment
                                        )
                                    }
                                >
                                    <TrashIcon height={20} width={20} />
                                </StyledIconButton>
                            </div>
                        );
                    })}

                    <StyledButton
                        rounded
                        outline={false}
                        variant="light"
                        className="white-space-nowrap m-0 w-100"
                        onClick={toggleNewSegmentModal}
                        style={{
                            backgroundColor: "#FFFFFF",
                        }}
                    >
                        <span style={{ color: "black" }}>
                            {t("Add filter")}
                        </span>
                    </StyledButton>
                </div>
                <div className="d-md-flex">
                    <SelectFilter
                        options={[
                            t("General"),
                            t("Sales mode"),
                            t("Sales support"),
                            t("Payment methods"),
                            t("Order city"),
                            t("Families"),
                            t("Sub-families"),
                        ]}
                    />
                </div>
            </div>
            <ModalWrapper
                isModalOpened={isModalOpened}
                toggleNewSegmentModal={toggleNewSegmentModal}
                activeTab={activeTab}
                toggleNav={toggleNav}
            />
        </React.Fragment>
    );
}
