import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Transactions/store";

import {
    getAdvancedPeriodStringParam,
    formatCurrency,
    capitalize,
    removeArrayOfObjectsDuplicates,
} from "@helpers/general";
import { fetcherWithToken } from "@helpers/fetcherWithToken";

import ModalWrapper from "./ModalWrapper";
import Table from "./Table";

export default function OrdersDetails() {
    const { t } = useTranslation();

    const { selectedSegments, segmentQueryString } = useSnapshot(store);

    const advancedDate = useSelector((state: any) => state.advancedDate);
    const period = useSelector((state: any) => state.period);
    const selections = useSelector((state: any) => state.selections);

    const [orders, setOrders] = React.useState<any[]>([]);

    const columns: any = React.useMemo(
        () => [
            {
                Header: t("Order number"),
                accessor: "order_number",
            },
            {
                Header: t("Shop"),
                accessor: (order: OrderType) => {
                    return capitalize(order.shop_name);
                },
            },
            {
                Header: t("Total"),
                accessor: (order: OrderType) => {
                    return formatCurrency(Number(order.turnover));
                },
            },
            {
                Header: t("Sales mode"),
                accessor: (order: OrderType) => {
                    return t(capitalize(order.sale_mode));
                },
            },
            {
                Header: t("Sales support"),
                accessor: (order: OrderType) => {
                    return t(capitalize(order.sale_support));
                },
            },
            {
                Header: t("Payment method"),
                accessor: (order: OrderType) => {
                    return t(capitalize(order.regulation));
                },
            },
            {
                Header: t("Creation date"),
                accessor: (order: OrderType) => {
                    return moment(order.creation_date).format(
                        "hh:mm DD/MM/YYYY"
                    );
                },
            },
            {
                Header: t("Actions"),
                accessor: (order: OrderType) => (
                    <ModalWrapper
                        order={order}
                        buttonText={t("Details")}
                        buttonProps={{
                            className: "btn-block mr-1 mb-sm-1 btn-sm",
                            color: "secondary",
                            outline: true,
                        }}
                    />
                ),
            },
        ],
        [t]
    );

    React.useEffect(() => {
        if (selectedSegments.length > 0) {
            let localOrdersData: any[] = [];

            selectedSegments.forEach(async (segment: string) => {
                const apiResult = await fetcherWithToken(
                    `${
                        process.env.REACT_APP_API_V1_URL
                    }/statistics/transactions/orders?${getAdvancedPeriodStringParam(
                        period,
                        advancedDate
                    )}${
                        selections.designation &&
                        selections.designation !== "my_favorites"
                            ? `&selected=${selections.designation}`
                            : ""
                    }${segment !== "" ? `&segment=${segment}` : ""}`
                );

                localOrdersData.push(...apiResult.data);
                setOrders([...removeArrayOfObjectsDuplicates(localOrdersData)]);
            });
        } else {
            const getDefaultData = async function () {
                const apiResult = await fetcherWithToken(
                    `${
                        process.env.REACT_APP_API_V1_URL
                    }/statistics/transactions/orders?${getAdvancedPeriodStringParam(
                        period,
                        advancedDate
                    )}${
                        segmentQueryString !== ""
                            ? `&segmentObject=${segmentQueryString}`
                            : ""
                    }${
                        selections.designation &&
                        selections.designation !== "my_favorites"
                            ? `&selected=${selections.designation}`
                            : ""
                    }`
                );

                if (apiResult.error) {
                    throw Error(t("There's an error"));
                } else {
                    setOrders(apiResult.data);
                }
            };

            getDefaultData();
        }
    }, [
        selections,
        selectedSegments,
        advancedDate,
        segmentQueryString,
        period,
        t,
    ]);

    return (
        <Card>
            <CardBody>
                <Table columns={columns} data={orders} />
            </CardBody>
        </Card>
    );
}
