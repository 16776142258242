import React from "react";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { mutate } from "swr";
import { isMobile } from "react-device-detect";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import useResource from "@hooks/useResource";

import store from "@pages/MySelections/store";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import ErrorPage from "@components/Common/ErrorPage";

import SearchFranchisesFormGroupWrapper from "./SearchFranchisesFormGroupWrapper";
import SelectFranchiseFormGroupWrapper from "./SelectFranchiseFormGroupWrapper";
import MySavedSelectionsSelectFormGroupWrapper from "./MySavedSelectionsSelectFormGroupWrapper";
import MyFavoritesButtonWrapper from "./MyFavoritesButtonWrapper";
import ValidateSelectionButtonWrapper from "./ValidateSelectionButtonWrapper";
import SaveMySelectionModalWrapper from "./SaveMySelectionModalWrapper";
import SaveMySelectionButtonWrapper from "./SaveMySelectionButtonWrapper";

import "./index.css";

export default function FranchisesSelection() {
    const { t } = useTranslation();
    const { selectedSelection } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const customSelectStyles = {
        container: () => {
            return {
                marginTop: "0.25rem",
            };
        },
        control: (provided: any) => ({
            ...provided,
            minWidth: isMobile ? "260px" : "200px",
            minHeight: "40px",
            height: "40px",
            margin: "1px",
            borderRadius: "5px",
            padding: "0px",
        }),
    };

    const { resourceData: selectionsData, error } = useResource(
        process.env.REACT_APP_API_V1_URL + "/selections/selections-list"
    );

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [selectionsOptions, setSelectionsOptions] = React.useState<
        SelectionType[]
    >([]);

    function toggleOpeningModal() {
        if (
            MySelectionsStore.checkedFranchises.length === 0 ||
            selectedSelection.label !== undefined
        ) {
            return swal({
                icon: "warning",
                content: <p>{t("Choose a selection")}!</p>,
            });
        }
        setIsModalOpened(!isModalOpened);
    }

    async function sendSavingSelectionRequest(
        selection: SelectionType,
        isValidate: boolean = false
    ) {
        try {
            await fetch(
                process.env.REACT_APP_API_V1_URL + "/selections/save-selection",
                {
                    headers: {
                        accept: "application/json",
                        "content-type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(selection),
                    method: "POST",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error while saving selection!");
                    }

                    setSelectionsOptions((oldSelections: any) => {
                        return oldSelections.concat({
                            value: selection.selection,
                            label: selection.designation,
                        });
                    });

                    if (!isValidate) {
                        return swal({
                            icon: "success",
                            content: (
                                <p>{t("Selection saved successfully")}!</p>
                            ),
                        });
                    }
                });

            mutate(
                process.env.REACT_APP_API_V1_URL +
                    "/​selections​/selections-list"
            );
        } catch (e: any) {
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
            });
        }
    }

    React.useEffect(() => {
        setSelectionsOptions(
            selectionsData.data
                ?.filter((el: { selection: any[]; designation: string }) => {
                    return !el.designation.includes("temp_selection_");
                })
                .map(function (option: SelectionType) {
                    return {
                        value: option.selection,
                        label: option.designation,
                    };
                })
        );
    }, [selectionsData]);

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            <React.Fragment>
                <div className="grid-xl-franchises-selection__clz grid-gap-xxl__clz mb-4">
                    <div className="w-xs-100 w-sm-100 grid-md-franchises-selection__clz">
                        <SearchFranchisesFormGroupWrapper />
                        <SelectFranchiseFormGroupWrapper
                            customSelectStyles={customSelectStyles}
                        />
                        {error ? (
                            <ErrorPage />
                        ) : (
                            <MySavedSelectionsSelectFormGroupWrapper
                                options={selectionsOptions}
                                customSelectStyles={customSelectStyles}
                            />
                        )}
                    </div>
                    <div className="mt-0 mt-xl-4 w-sm-100 grid-md-franchises-selection-btn__clz grid-xl-franchises-selection-btn__clz">
                        <MyFavoritesButtonWrapper />
                        <SaveMySelectionButtonWrapper
                            toggleOpeningModal={toggleOpeningModal}
                        />
                        <ValidateSelectionButtonWrapper
                            sendSavingSelectionRequest={
                                sendSavingSelectionRequest
                            }
                        />
                    </div>
                </div>
                <SaveMySelectionModalWrapper
                    isModalOpened={isModalOpened}
                    toggleOpeningModal={toggleOpeningModal}
                    sendSavingSelectionRequest={sendSavingSelectionRequest}
                    options={selectionsOptions}
                />
            </React.Fragment>
        </React.Suspense>
    );
}
