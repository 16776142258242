import React from "react";

import logo from "@assets/images/etk-reporting-small-logo.png";
import smallLogo from "@assets/images/etk-hq.png";

import "./SidebarLogo.css";

type SidebarLogoPropsType = { type: string };

export default function SidebarLogo({ type }: SidebarLogoPropsType) {
    let height = type === "default" ? 40 : 25;
    let logoSrc = type === "default" ? logo : smallLogo;

    return (
        <img
            src={logoSrc}
            className={type === "default" ? "" : "logo__clz"}
            alt="Logo"
            height={height}
        />
    );
}
