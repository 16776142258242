import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    capitalize,
    getRoundedArrayNumbers,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function Top10Products() {
    const { t } = useTranslation();

    const { employeesQueryString } = useSnapshot(store);

    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
        top10ProductsSelection,
    } = React.useContext(StatisticsContext);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: offersData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/chef/percentage-products?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${employeesQueryString !== "" ? `&chef=${employeesQueryString}` : ""}${
            top10ProductsSelection !== ""
                ? `&products=${top10ProductsSelection}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSeries: number[] = [];
        let localOffers: string[] = [];

        let shopsNumber = offersData.data.length;

        offersData.data?.forEach((item: any) => {
            localSeries.push(item.value);

            if (shopsNumber === 1) {
                let designation = item.designation.split("|");
                localOffers.push(designation[0]);
            } else {
                localOffers.push(item.designation);
            }
        });

        setData(
            getRoundedArrayNumbers(localSeries.filter((item) => item !== 0))
        );

        setLabels(
            localOffers.length > 0
                ? localOffers.map((item) => t(capitalize(item)))
                : [t("No data")]
        );
        setPercentageData!(localSeries);
    }, [offersData, setPercentageData, t]);

    const donutChartData: SeriesDataType = {
        data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <DonutChart title={t("Offers")} data={donutChartData}>
                    <LegendPopoverWrapper
                        title={t("Offers")}
                        labels={labels || []}
                    />
                </DonutChart>
            )}
        </React.Fragment>
    );
}
