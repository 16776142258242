import React from "react";
import Select, { components } from "react-select";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store, {
    setCheckedFranchises,
    setFilteredFranchises,
    setMySavedSelectionSelectAction,
    setSearchedElementInputValue,
} from "@pages/MySelections/store";

import { getUniqueArrayOfSimpleValues } from "@helpers/general";

type PropsType = {
    options: any[];
    customSelectStyles: any;
};

export default function MySavedSelectionsSelectFormGroupWrapper({
    options,
    customSelectStyles,
}: PropsType) {
    const { t } = useTranslation();

    const {
        selectedSelectionValue,
        setSelectedSelectionValue,
        setIsFranchiseChosen,
        setSelectedFranchiseValue,
        setSearchedFranchises,
        setSelectedSelection,
    } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const ValueContainer = ({
        children,
        getValue,
        hasValue,
        ...props
    }: any) => {
        return !hasValue || getValue().length <= 1 ? (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        ) : (
            <components.ValueContainer {...props}>
                {getValue().length > 1
                    ? t("Selected element", {
                          selectedItems: getValue().length,
                          count: getValue().length,
                      })
                    : children}

                {React.Children.map(children, (child) => {
                    return child.type === components.Input ? child : null;
                })}
            </components.ValueContainer>
        );
    };

    function handleMySavedSelectionsSelectOnChangeEvent(
        selectedSelectionValue: any,
        action: any
    ) {
        setSearchedElementInputValue!("");
        setSelectedSelectionValue!(selectedSelectionValue);
        setSelectedSelection!(selectedSelectionValue);
        setMySavedSelectionSelectAction(action!);
        if (action.action === "clear" || action.action === "remove-value") {
            setIsFranchiseChosen!(false);
            setCheckedFranchises([]);
            setSelectedSelection!({});
            setFilteredFranchises([]);
        } else {
            setCheckedFranchises(MySelectionsStore.checkedFranchises);
        }

        if (selectedSelectionValue) {
            setSearchedFranchises!([]);
            setSelectedFranchiseValue!([]);
            let franchisesIdsArray: any[] = [];
            let arrayOfSelection: any[] = [];

            selectedSelectionValue.forEach((datum: any) => {
                datum.value.forEach((el: any) => {
                    arrayOfSelection.push(el);
                    franchisesIdsArray.push(el.franchise_id);
                });
            });

            let selectionFranchisesIds: {
                franchise_id: string;
                shops_id: Array<string | number>;
            }[] = [];

            franchisesIdsArray = getUniqueArrayOfSimpleValues(
                franchisesIdsArray
            );

            franchisesIdsArray.forEach((id) => {
                selectionFranchisesIds.push({
                    franchise_id: id,
                    shops_id: [],
                });
            });

            arrayOfSelection.forEach((selection: any) => {
                let index = selectionFranchisesIds.findIndex(
                    (a: any) => a.franchise_id === selection.franchise_id
                );
                selectionFranchisesIds[
                    index
                ].shops_id = getUniqueArrayOfSimpleValues([
                    ...selectionFranchisesIds[index].shops_id,
                    ...selection.shops_id,
                ]);
            });

            let filteredFranchises: any[] = [];

            MySelectionsStore.allFranchises.forEach(
                (franchise: FranchisesDataType) => {
                    selectionFranchisesIds?.forEach((selection: any) => {
                        if (selection.franchise_id === franchise.id) {
                            let shops = franchise.shops!.filter(function (
                                shop: any
                            ) {
                                // `shops ids` array may contains `string`s or `number`s
                                return (
                                    selection.shops_id
                                        .map(Number)
                                        .includes(Number(shop.id)) ||
                                    selection.shops_id.includes(shop.id)
                                );
                            });
                            let shop = shops.length;

                            filteredFranchises.push({
                                ...franchise,
                                shops,
                                shop,
                            });
                        }
                    });
                }
            );

            setFilteredFranchises(filteredFranchises);
        }
    }

    return (
        <FormGroup className="w-100 w-xs-100 franchises-font-size-xl__clz">
            {t("My saved sections")}

            <Select
                isSearchable={true}
                isClearable={true}
                onChange={handleMySavedSelectionsSelectOnChangeEvent}
                isMulti
                value={selectedSelectionValue}
                options={options}
                placeholder={t("Select a list")}
                noOptionsMessage={() => t("no option found")}
                className="franchises-font-size-xl__clz mt-select__clz"
                menuPosition="fixed"
                styles={{
                    ...customSelectStyles,
                    container: (provided: any) => {
                        return {
                            ...provided,
                            marginTop: "0.25rem",
                            position:
                                selectedSelectionValue !== undefined &&
                                selectedSelectionValue !== null &&
                                selectedSelectionValue!.length > 1 &&
                                !isMobile
                                    ? "absolute"
                                    : "relative",
                        };
                    },
                    control: (provided: any) => ({
                        ...provided,
                        minWidth: isMobile ? "260px" : "200px",
                        minHeight: "40px",
                        height: "40px",
                        margin: "1px",
                        borderRadius: "5px",
                        padding:
                            isMobile && selectedSelectionValue!.length > 1
                                ? "2px 0"
                                : "0px",
                    }),
                    indicatorsContainer: (provided: any) => ({
                        ...provided,
                        alignItems: isMobile ? "unset" : "center",
                        position: isMobile ? "absolute" : provided.position,
                        right: isMobile ? 0 : provided.position,
                    }),
                }}
                components={{ ValueContainer }}
            />
        </FormGroup>
    );
}
