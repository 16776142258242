import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
    getSalesSupportQueryString,
    getStringsArrayUniqueValues,
    getTypeParam,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function PercentageSalesSupportCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: salesSupportData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-support/percentage-sales-support?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(statisticsStore.salesSupportCards.modes)
                .length > 0
                ? `&sales-support=${getSalesSupportQueryString(
                      statisticsStore.salesSupportCards.modes
                          .map(getUntranslatedString)
                          .map((el: any) => el?.toUpperCase())
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSeries: number[] = [];
        let localSalesModes: string[] = [];

        salesSupportData.data?.forEach((item: any) => {
            localSeries.push(item.value);
            localSalesModes.push(item.designation);
        });

        setData(
            getRoundedArrayNumbers(localSeries.filter((item) => item !== 0))
        );
        setLabels(
            getStringsArrayUniqueValues(
                localSalesModes.length > 0
                    ? localSalesModes.map((item) => t(item))
                    : [t("No data")]
            )
        );
        setPercentageData!(localSeries);
    }, [salesSupportData, t, setPercentageData]);

    const dountPurchaseSupportData = {
        data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Sales support")}
                        data={dountPurchaseSupportData}
                    >
                        <LegendPopoverWrapper
                            title={t("Sales support")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
