import React from "react";
import classnames from "classnames";
import {
    Modal,
    ModalBody,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    ModalFooter,
    NavLink,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";

import { clearStore } from "@pages/Statistics/Transactions/store";

import CustomModalFooter from "./CustomModalFooter";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import NewSegment from "@pages/Statistics/Transactions/NewSegment";
import SavedSegments from "@pages/Statistics/Transactions/SavedSegments";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

type PropsType = {
    isModalOpened: boolean;
    toggleNewSegmentModal: () => void;
    activeTab: number;
    toggleNav: (tab: number) => void;
};

export default function ModalWrapper({
    isModalOpened,
    toggleNewSegmentModal,
    activeTab,
    toggleNav,
}: PropsType) {
    const { t } = useTranslation();

    React.useEffect(() => {
        if (activeTab === 2) {
            clearStore();
        }
    }, [activeTab]);

    return (
        <Modal
            isOpen={isModalOpened}
            toggle={toggleNewSegmentModal}
            centered
            className="segment-modal__clz px-3"
            style={{ maxWidth: "1400px" }}
            backdrop="static"
        >
            <ModalBody className="p-0 d-md-flex">
                <Nav
                    tabs
                    className="bg-dark flex-md-column tab-width__clz w-sm-100"
                    style={{ borderTopLeftRadius: "0.4rem" }}
                >
                    <NavItem
                        className={classnames({
                            "mm-active advanced-nav-item-active__clz":
                                activeTab === 1,
                        })}
                    >
                        <NavLink
                            className={`${classnames({
                                "active nav-link-active__clz": activeTab === 1,
                            })} border-none__clz nav-link__clz`}
                            onClick={() => {
                                toggleNav(1);
                            }}
                        >
                            {t("New filter")}
                        </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                            "mm-active advanced-nav-item-active__clz":
                                activeTab === 2,
                        })}
                    >
                        <NavLink
                            className={`${classnames({
                                "active nav-link-active__clz": activeTab === 2,
                            })} border-none__clz nav-link__clz`}
                            onClick={() => {
                                toggleNav(2);
                            }}
                        >
                            {t("Saved filters")}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="w-100">
                    <TabPane tabId={1}>
                        <NewSegment />
                    </TabPane>
                    <TabPane tabId={2}>
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <CustomErrorBoundary>
                                <SavedSegments
                                    toggleNav={toggleNav}
                                    toggleNewSegmentModal={
                                        toggleNewSegmentModal
                                    }
                                />
                            </CustomErrorBoundary>
                        </React.Suspense>
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <StyledButton
                    outline
                    rounded
                    variant="primary"
                    onClick={toggleNewSegmentModal}
                    style={{
                        marginRight: "0.5rem",
                    }}
                >
                    {t("Close")}
                </StyledButton>
                {activeTab === 1 ? (
                    <CustomModalFooter
                        toggleNewSegmentModal={toggleNewSegmentModal}
                    />
                ) : null}
            </ModalFooter>
        </Modal>
    );
}
