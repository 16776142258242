import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import OrderRow from "./OrderRow";

export default function Orders({
    title,
    totalAmount,
    totalPercentage,
    chartPercentage,
}: {
    title: string;
    totalAmount: number;
    totalPercentage: number;
    chartPercentage: number;
}) {
    return (
        <Card className="order-card-mb order-xs-card-mb">
            <CardTitle className="rounded-top dark-bg__clz">
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>
            </CardTitle>
            <CardBody className="py-0">
                <OrderRow
                    amount={totalAmount}
                    percentage={totalPercentage}
                    chartPercentage={chartPercentage}
                />
            </CardBody>
        </Card>
    );
}
