import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import {
    StyledButton,
    StyledIconButton,
    AddIcon,
    StyledSearchInput,
} from "@aureskonnect/react-ui";

import { addRegulation } from "@store/regulations/actions";

import useResource from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import Pagination from "@pages/Statistics/Transactions/Table/Pagination";

import "@assets/widths.css";

export default function SecondStep({ next }: any) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const groupName = useSelector((state: any) => state.groups);
    const selectedRegulationList = useSelector(
        (state: any) => state.regulations
    );

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/regulations-modes`
    );

    const [
        filteredRegulationGroup,
        setFilteredRegulationGroup,
    ] = React.useState(resourceData.data);

    const handleSelectedRegulationMode = React.useCallback(
        (regulation: any) => {
            dispatch(addRegulation(regulation));
            setFilteredRegulationGroup(
                filteredRegulationGroup.filter((datum: any) => {
                    return regulation.toLowerCase() !== datum.toLowerCase();
                })
            );
        },
        [filteredRegulationGroup, dispatch]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Designation"),
                accessor: (regulationMode: any): string => regulationMode,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (regulation: any) {
                    return (
                        <div>
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                style={{
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block"
                                onClick={() =>
                                    handleSelectedRegulationMode(regulation)
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="PlusIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleSelectedRegulationMode(regulation)
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleSelectedRegulationMode]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredRegulationGroup,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: "selection",
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        ),
                        Cell: ({ row }) => (
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        ),
                        disableGlobalFilter: true,
                    },

                    ...columns,
                ];
            });
        }
    );

    function handleMultipleSelectionListButtonOnClickEvent() {
        selectedFlatRows
            .map((row: any) => row.original)
            .forEach((item: string) => {
                dispatch(addRegulation(item));
            });

        setFilteredRegulationGroup(
            filteredRegulationGroup.filter((saleMode: any) => {
                return !selectedFlatRows
                    .map((row: any) => row.original)
                    .map((s) => s.toLowerCase())
                    .includes(saleMode.toLowerCase());
            })
        );
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="d-md-flex justify-content-md-between align-items-md-center">
                <div className="d-md-flex align-items-md-center my-2">
                    <span className="mb-0 mx-2 h5 white-space-nowrap">
                        {t(groupName)}
                    </span>
                    <span
                        className="divider mt-0 mx-2 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />
                    <StyledSearchInput
                        onChange={(e) => {
                            setGlobalFilter(e.target.value);
                        }}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mt-2 ml-md-3 mt-md-0"
                    />
                </div>
                <div className="d-md-flex m-2">
                    <StyledButton
                        outline={false}
                        rounded
                        variant="secondary"
                        className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset"
                        disabled={selectedFlatRows.length < 2}
                        onClick={handleMultipleSelectionListButtonOnClickEvent}
                    >
                        {t("Add")}
                    </StyledButton>
                    <StyledButton
                        outline={false}
                        rounded
                        variant="secondary"
                        className="w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                        disabled={selectedRegulationList.length === 0}
                        onClick={() => next()}
                    >
                        {`${t("My selection")} (${
                            selectedRegulationList.length
                        })`}
                    </StyledButton>
                </div>
            </div>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <BTable responsive {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="white-space-nowrap"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="white-space-nowrap align-middle"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </BTable>
                    {filteredRegulationGroup.length > ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
