import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "@sindresorhus/slugify";

import { formatCurrency, getRandomInt } from "@helpers/general";

type BarChartCardWrapperPropsType = {
    title: string;
    series: SeriesDataType[];
    categories: string[];
    type: string;
    shops?: string[];
};

export default function BarChartCardWrapper({
    title,
    series,
    categories,
    type,
    shops,
}: BarChartCardWrapperPropsType) {
    const { t } = useTranslation();

    const options = {
        tooltip: {
            enabled: true,
            shared: true,
            x: {
                formatter: (
                    value: any,
                    { series, seriesIndex, dataPointIndex, w }: any
                ) => {
                    return `${value}${
                        shops ? `/${shops![dataPointIndex]}` : ""
                    }`;
                },
            },
            y: {
                formatter: (seriesName: any) =>
                    type !== t("Tickets")
                        ? formatCurrency(seriesName)
                        : seriesName,
                title: {
                    formatter: (seriesName: any) => seriesName,
                },
            },
            followCursor: true,
        },
        chart: {
            type: "bar",
            height: 310,
            stacked: true,
            dropShadow: {
                enabled: false,
                blur: 1,
                opacity: 10,
            },
            toolbar: {
                show: true,
                tools: {
                    download: false,
                },
            },
        },
        colors: ["#ff0000", "#e68b0b", "#05f76a"],
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: 0,
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (e: any) {
                    return e;
                },
            },
        },
        noData: {
            text: t("No data"),
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
        },
    };

    return (
        <Card className="mb-0">
            <CardTitle className="rounded-top dark-bg__clz">
                <Link
                    to="/coming-soon"
                    className="d-none btn btn-outline-dark cards btn-sm float-right d-none"
                >
                    {t("See more")} <i className="mdi mdi-arrow-right ml-1"></i>
                </Link>
                <h4 className="card-title my-2 mx-3 text-white">{title}</h4>{" "}
            </CardTitle>
            <CardBody>
                <div className="p-0 apex-charts">
                    <ReactApexChart
                        key={`apex-chart__${getRandomInt(
                            series.length + Math.random()
                        )}_${slugify(type)}`}
                        options={options}
                        series={series}
                        type="bar"
                        height="320"
                    />
                </div>
            </CardBody>
        </Card>
    );
}
