import React from "react";
import { Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

type ReportPropsType = {
    type: string;
    report: ReportType;
    id: number | string;
    toggleTabs: Function;
};

export default function Report({
    type,
    id,
    report,
    toggleTabs,
}: ReportPropsType) {
    const { t } = useTranslation();

    return (
        <Card
            key={`_col_${id}`}
            className={`${classnames({
                "active active-card-theme__clz": report.title === type,
                "dark-bg__clz": report.title !== type,
                "mr-0": id === 2,
                "mr-2": id !== 2,
            })} mini-stats-wid w-100 order-card-mb order-lg-card-mb order-xl-card-mb`}
        >
            <CardBody
                onClick={() => {
                    toggleTabs(report.toggle, report.title);
                }}
                style={{ cursor: "pointer" }}
            >
                <div className="d-flex justify-content-between pt-1">
                    <div className="d-flex flex-column w-75">
                        <p className="font-weight-medium  text-white">
                            {t(report.title)}
                        </p>
                        <div>
                            <h4 className="mb-0 text-white">
                                {report.description}
                            </h4>
                        </div>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle">
                        <span className="avatar-title bg-dark">
                            <i
                                className={`bx ${report.iconClass} font-size-24`}
                            ></i>
                        </span>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
