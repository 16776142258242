import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StyledButton, StyledSearchInput } from "@aureskonnect/react-ui";
import BTable from "react-bootstrap/Table";
import { usePagination, useTable, useGlobalFilter } from "react-table";

import useResource from "@hooks/useResource";

import { selectGroup } from "@store/settings/actions";

import { ITEMS_PER_PAGE } from "@constants/index";

import Pagination from "@pages/Statistics/Transactions/Table/Pagination";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";

import "@assets/widths.css";

export default function FirstStep({ next }: any) {
    const { t } = useTranslation();

    const { mutate } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/regulations-modes`
    );

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/regulations/groups`
    );

    const [
        filteredRegulationGroup,
        setFilteredRegulationGroup,
    ] = React.useState<any[]>([]);

    const dispatch = useDispatch();

    const handleSelectGroupButtonOnClickEvent = React.useCallback(
        async (groupName: string) => {
            dispatch(selectGroup(groupName));
            mutate();
            next();
        },
        [dispatch, mutate, next]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("The groups"),
                accessor: (regulationMode: any): string => t(regulationMode),
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (group: any) {
                    return (
                        <StyledButton
                            outline
                            rounded
                            variant="secondary"
                            onClick={() =>
                                handleSelectGroupButtonOnClickEvent(group)
                            }
                            style={{ float: "right" }}
                        >
                            {t("Select")}
                        </StyledButton>
                    );
                },
            },
        ],
        [t, handleSelectGroupButtonOnClickEvent]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: filteredRegulationGroup,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setFilteredRegulationGroup(resourceData.data);
    }, [resourceData]);

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <div>
            <StyledSearchInput
                onChange={(e) => {
                    setGlobalFilter(e.target.value);
                }}
                placeholder={`${t("Search")} ...`}
                className="w-md-50 w-lg-50 w-xl-40 m-2"
            />
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <BTable responsive {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="white-space-nowrap"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="white-space-nowrap align-middle"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </BTable>
                    {filteredRegulationGroup.length > ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </CustomErrorBoundary>
            )}
        </div>
    );
}
