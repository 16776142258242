import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function PercentageTop10Employees() {
    const { t } = useTranslation();

    const { employeesQueryString } = useSnapshot(store);
    const {
        period,
        type,
        selections,
        advancedDate,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: top10EmployeesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/cashier/percentage?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            employeesQueryString !== ""
                ? `&cashier=${employeesQueryString}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        let shopsNumber = top10EmployeesData.data.length;

        top10EmployeesData.data?.forEach((item: any) => {
            if (shopsNumber === 1) {
                let designation = item.designation.split("|");
                localLabels.push(designation[0]);
            } else {
                localLabels.push(item.designation);
            }

            localData.push(item.value);
        });

        setData(getRoundedArrayNumbers(localData.filter((item) => item !== 0)));
        setLabels(
            localLabels.length > 0
                ? localLabels.map((item) => t(item))
                : [t("No data")]
        );
        setPercentageData!(localData);
    }, [top10EmployeesData, t, setPercentageData]);

    const donutChartTop10EmployeesData: SeriesDataType = {
        data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <DonutChart
                    title={`${t("Employees")} / %`}
                    data={donutChartTop10EmployeesData}
                >
                    <LegendPopoverWrapper
                        title={`${t("Employees")} / %`}
                        labels={labels || []}
                    />
                </DonutChart>
            )}
        </React.Fragment>
    );
}
