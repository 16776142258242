import React from "react";
import { Card, CardBody, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
} from "@helpers/general";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import ErrorPage from "@components/Common/ErrorPage";

export default function StatisticsTop10ProductsCol() {
    const { t } = useTranslation();
    const { employeesQueryString } = useSnapshot(store);

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
        top10ProductsSelection,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [data, setData] = React.useState<any[]>([]);

    const { resourceData: statisticsOffersData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/chef/products?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${employeesQueryString !== "" ? `&chef=${employeesQueryString}` : ""}${
            top10ProductsSelection !== ""
                ? `&products=${top10ProductsSelection}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setData(
            selections.selection.length === 1
                ? getStatisticsData(
                      statisticsOffersData.data,
                      categories,
                      period,
                      advancedDate
                  ).map((item) => {
                      return {
                          name: item.name?.split("|")[0],
                          data: item.data,
                      };
                  })
                : getStatisticsData(
                      statisticsOffersData.data,
                      categories,
                      period,
                      advancedDate
                  )
        );
        setHistogramData!(statisticsOffersData.data);
    }, [
        statisticsOffersData,
        categories,
        period,
        advancedDate,
        setHistogramData,
        t,
        selections,
    ]);

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm">
                <Nav pills className="d-none navtab-bg nav__clz nav__clz-sm">
                    <Link
                        style={{ paddingTop: "8px" }}
                        to="/coming-soon"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <CardBody className="pb-0 chart-order chart-order-sm chart-order-md chart-order-lg chart-order-xl">
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <ChartWrapper series={data} categories={categories} />
                    )}
                </CardBody>
            </CardBody>
        </Card>
    );
}
