import ReduxStateChangeListener from "redux-state-change-listener";

import store from "../store";

export default function subscribeToStoreChange(
    handlerFunc: Function,
    statePortion: string
) {
    function onStateChanged(
        newStatePortion: any,
        newState: any,
        prevStatePortion: any,
        oldState: any
    ) {
        if (prevStatePortion !== newStatePortion) {
            handlerFunc();
        }
    }

    const stateCallbackManager = new ReduxStateChangeListener(store);

    stateCallbackManager.register(
        (state: any) => state[statePortion],
        onStateChanged
    );

    stateCallbackManager.start();
}
