import React from "react";

type SettingsArticlesContextType = {
    groups: any[];
    setGroups: Function;
};

export const SettingsArticlesContext = React.createContext<
    SettingsArticlesContextType
>({
    groups: [],
    setGroups: () => {},
});
