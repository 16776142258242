import React from "react";
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function SidebarContent() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    React.useEffect(() => {
        var pathName = pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu")!;

            var items = ul.getElementsByTagName("a");

            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }

            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };

        initMenu();
    }, [pathname]);

    function activateParentDropdown(item: any): boolean {
        Array.from(document.querySelectorAll("li a.active")!).forEach(
            (element) => {
                element.classList.remove("active");
            }
        );

        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active");
                    parent3.childNodes[0].classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }

    return (
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                    <Link to="/dashboard" className="waves-effect">
                        <i className="bx bx-home"></i>
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-bar-chart"></i>
                        <span>{t("Statistics")}</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link to="statistics-turnover">
                                {t("Turnover")}
                            </Link>
                        </li>
                        <li>
                            <Link to="statistics-tickets">{t("Tickets")}</Link>
                        </li>
                        <li>
                            <Link to="statistics-average-basket">
                                {t("Average basket")}
                            </Link>
                        </li>
                        <li>
                            <Link to="statistics-sales-mode">
                                {t("Sales mode")}
                            </Link>
                        </li>
                        <li>
                            <Link to="statistics-sales-support">
                                {t("Sales support")}
                            </Link>
                        </li>
                        <li>
                            <Link to="regulation-modes">
                                {t("Payment methods")}
                            </Link>
                        </li>
                        <li>
                            <Link to="families">{t("Families")}</Link>
                        </li>
                        <li>
                            <Link to="sub-families">{t("Sub-families")}</Link>
                        </li>
                        <li>
                            <Link to="statistics-products">{t("Items")}</Link>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <span>{t("Employees")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="employees-cashier">
                                        {t("Cashier")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="employees-cook">{t("Cook")}</Link>
                                </li>
                                <li>
                                    <Link to="employees-delivery-man">
                                        {t("Delivery man")}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="statistics-out-of-stock">
                                {t("Out of stock")}
                            </Link>
                        </li>
                        <li>
                            <Link to="transactions">{t("Transactions")}</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-cog"></i>
                        <span>{t("Setting")}</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link to="statistics-settings">
                                {t("Statistics")}
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}
