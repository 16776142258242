import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    capitalize,
    getAdvancedPeriodStringParam,
    getRegulationModesQueryString,
    getRoundedArrayNumbers,
    getStringsArrayUniqueValues,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function SalesModeCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: salesModeData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/regulations/percentage-sales-mode?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getRegulationModesQueryString(
                statisticsStore.regulationModesCards.modes
            ).length > 0
                ? `&regulation=${getRegulationModesQueryString(
                      statisticsStore.regulationModesCards.modes
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localSeries: number[] = [];
        let localSalesModes: string[] = [];

        salesModeData.data?.forEach((item: any) => {
            localSeries.push(item.value);
            localSalesModes.push(item.designation);
        });

        setData(
            getRoundedArrayNumbers(localSeries.filter((item) => item !== 0))
        );

        setLabels(
            getStringsArrayUniqueValues(
                localSalesModes.length > 0
                    ? localSalesModes.map((item) => t(capitalize(item)))
                    : [t("No data")]
            )
        );
        setPercentageData!(localSeries);
    }, [salesModeData, t, setPercentageData]);

    const donutChartSalesModesData: SeriesDataType = {
        data: data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(label))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Sales mode")}
                        data={donutChartSalesModesData}
                    >
                        <LegendPopoverWrapper
                            title={t("Sales mode")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
