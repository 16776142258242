import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import slugify from "@sindresorhus/slugify";
import moment from "moment";

import useResource from "@hooks/useResource";

import {
    getRandomInt,
    formatCurrency,
    getTypeParam,
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    capitalize,
    formatChartLabels,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";

import "@assets/scss/dashboard.scss";

export default function ChartWrapper() {
    const { t } = useTranslation();
    const type = useSelector((state: { type: string }) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const period = useSelector((state: any) => state.period);
    const selections = useSelector((state: any) => state.selections);

    const [data, setData] = React.useState<number[]>([]);
    const [categories, setCategories] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/dashboard/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
    }, [period, advancedDate]);

    React.useEffect(() => {
        let localData: number[] = [];
        categories.forEach((category: any, index: number) => {
            resourceData.data?.forEach((item: any) => {
                let localCategory = category;
                let localDesignation = item.designation;

                if (
                    advancedDate.startDate !== "" &&
                    advancedDate.endDate !== ""
                ) {
                    let daysBetweenDates = moment
                        .unix(Number(advancedDate.endDate))
                        .diff(
                            moment.unix(Number(advancedDate.startDate)),
                            "days"
                        );

                    if (daysBetweenDates === 0) {
                        localDesignation = item.designation;
                        localCategory = category;
                    } else if (daysBetweenDates <= 31) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(item.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(item.designation))
                                .format("MMM YYYY")
                        );
                    }
                } else if (
                    [
                        t("Current week"),
                        t("The last 7 days"),
                        t("The last 14 days"),
                        t("The last 30 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(item.designation))
                            .format("ddd DD-MM-YYYY")
                    );
                    localCategory = capitalize(
                        moment.unix(Number(category)).format("ddd DD-MM-YYYY")
                    );
                } else if (period === t("Current month")) {
                    localDesignation = capitalize(
                        moment.unix(Number(item.designation)).format("D")
                    );
                } else if (period === t("Current year")) {
                    localDesignation = capitalize(
                        moment.unix(Number(item.designation)).format("MMM YYYY")
                    );
                } else if (
                    [
                        t("The last 90 days"),
                        t("The last 180 days"),
                        t("The last 365 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment.unix(Number(item.designation)).format("MMM YYYY")
                    );
                }

                if (
                    localDesignation === localCategory ||
                    localDesignation.split(" ").includes(localCategory)
                ) {
                    localData.push(item.value);
                }
            });

            if (!localData[index]) {
                localData[index] = 0;
            }
        });

        setData(localData);
    }, [resourceData, advancedDate, period, t, categories]);

    const options: object = {
        chart: {
            zoom: {
                enabled: false,
            },
            width: "100%",
            height: 359,
            type: "line",
            stacked: false,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: true,
                    pan: false,
                    zoom: true,
                    zoomin: categories?.length > 3,
                    zoomout: categories?.length > 3,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "100%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: categories,
            labels: {
                formatter: (value: any) =>
                    formatChartLabels(value, advancedDate, period),
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return [t("Tickets"), t("Quantity sold")].includes(type)
                        ? Math.round(value)
                        : formatCurrency(value, 0);
                },
            },
            tickAmount: 1,
        },
        colors: ["#FF4500"],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: "bottom",
        },
        fill: {
            opacity: 1,
        },
        noData: {
            text: t("No data"),
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
        },
    };

    const series: SerieType[] = [
        {
            name: type,
            data,
        },
    ];

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <ReactApexChart
                    key={`apex-chart__${getRandomInt(
                        series.length + Math.random()
                    )}_${slugify(type)}`}
                    options={options}
                    series={series}
                    height="355"
                />
            )}
        </React.Fragment>
    );
}
