import React from "react";
import { Card, CardBody, Nav, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";
import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getAdvancedSeeMorePeriod,
    getFormattedCategoriesByPeriod,
    getNumericRegulations,
    getPhysicalRegulations,
    getSalesSupportQueryString,
    getStatisticsData,
    getTypeParam,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

import "@assets/apex-chart.css";

export default function StatisticsRegulationsCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        advancedDate,
        type,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const regulationType = useSelector(
        (state: RegulationTypeType) => state.regulationType
    );

    const statisticsStore = useSnapshot(store);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [regulationData, setRegulationData] = React.useState<
        SeriesDataType[]
    >([]);

    const {
        resourceData: statisticsRegulationData,
        error: statisticsRegulationDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-support/regulation?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(statisticsStore.salesSupportCards.modes)
                .length > 0
                ? `&sales-support=${getSalesSupportQueryString(
                      statisticsStore.salesSupportCards.modes
                          .map(getUntranslatedString)
                          .map((el: any) => el?.toUpperCase())
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setRegulationData(
            getStatisticsData(
                regulationType === t("All regulation methods")
                    ? statisticsRegulationData.data
                    : regulationType === t("Physical Payment Method")
                    ? getPhysicalRegulations(statisticsRegulationData.data)
                    : getNumericRegulations(statisticsRegulationData.data),
                categories,
                period,
                advancedDate
            )
        );

        if (regulationType === t("All regulation methods")) {
            setHistogramData!(statisticsRegulationData.data);
        } else if (regulationType === t("Physical Payment Method")) {
            setHistogramData!(
                getPhysicalRegulations(statisticsRegulationData.data)
            );
        } else {
            setHistogramData!(
                getNumericRegulations(statisticsRegulationData.data)
            );
        }
    }, [
        statisticsRegulationData,
        categories,
        period,
        advancedDate,
        t,
        regulationType,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "sales_support",
                    value: [
                        ...statisticsStore.salesSupportCards.modes
                            .filter(
                                (mode: string) =>
                                    mode !== t("All sales supports")
                            )
                            .map((item: string) => {
                                return getUntranslatedString(
                                    item
                                ).toUpperCase();
                            }),
                    ],
                },
            ],
        };

        setAdvancedLegend(t("Payment methods"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <Card>
            <CardBody>
                <Nav pills className="navtab-bg float-right d-none">
                    <Button
                        color="secondary"
                        outline
                        size="sm"
                        onClick={handleSeeMoreButtonOnClickEvent}
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Button>
                </Nav>
                <div className="apex-charts apex-chart__clz">
                    {statisticsRegulationDataError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <ChartWrapper
                                series={regulationData}
                                categories={categories}
                            />
                        </CustomErrorBoundary>
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
