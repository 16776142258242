import { proxy } from "valtio";
import { ActionMeta } from "react-select";

type MySelectionsStoreType = {
    checkedFranchises: any[];
    allFranchises: FranchisesDataType[];
    filteredFranchises: FranchisesDataType[];
    mySavedSelectionSelectAction: ActionMeta<OptionType>;
    searchedElementInputValue: string;
};

const store = proxy<MySelectionsStoreType>({
    checkedFranchises: [],
    allFranchises: [],
    filteredFranchises: [],
    mySavedSelectionSelectAction: {
        action: "select-option",
    },
    searchedElementInputValue: "",
});

export function setCheckedFranchises(checkedFranchises: any[]) {
    store.checkedFranchises = checkedFranchises;
}

export function setAllFranchises(allFranchises: any[]) {
    store.allFranchises = allFranchises;
}

export function setFilteredFranchises(filteredFranchises: any[]) {
    store.filteredFranchises = filteredFranchises;
}

export function setMySavedSelectionSelectAction(
    mySavedSelectionSelectAction: any
) {
    store.mySavedSelectionSelectAction = mySavedSelectionSelectAction;
}

export function setSearchedElementInputValue(searchedElementInputValue: any) {
    store.searchedElementInputValue = searchedElementInputValue;
}

export default store;
