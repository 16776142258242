import React from "react";
import { Row, Col } from "reactstrap";
import { useSnapshot } from "valtio";
import { useSelector } from "react-redux";

import store from "@pages/Statistics/Employees/store";

import useResource from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import MiniCard from "@components/Common/MiniCard";

export default function Cards() {
    const { employeesQueryString } = useSnapshot(store);

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [amount, setAmount] = React.useState<number>(0);

    const { resourceData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/cashier/global?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            employeesQueryString !== ""
                ? `&cashier=${employeesQueryString}`
                : ""
        }`
    );

    React.useEffect(() => {
        setAmount(resourceData.data);
    }, [resourceData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <Row className="cards__clz">
                    <Col>
                        <MiniCard title={type} amount={amount} />
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
}
