import React from "react";
import { Card, CardBody } from "reactstrap";

import MySelectionsContext from "../context";

import useResource from "@hooks/useResource";

import { setAllFranchises, setFilteredFranchises } from "../store";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import FranchisesTable from "./FranchisesTable";
import FranchisesSelection from "./FranchisesSelection";

export default function MySelectionsCard() {
    const { resourceData: franchisesShops, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/selections/franchises-shops-list`
    );

    const [isFranchiseChosen, setIsFranchiseChosen] = React.useState<boolean>(
        false
    );

    const [selectedFranchiseValue, setSelectedFranchiseValue] = React.useState<
        any[]
    >([]);

    const [searchedFranchises, setSearchedFranchises] = React.useState<
        FranchisesDataType[]
    >([]);
    const [expandedRows, setExpandedRows] = React.useState<any[]>([]);
    const [selectedSelectionValue, setSelectedSelectionValue] = React.useState<
        any[]
    >([]);
    const [selectedSelection, setSelectedSelection] = React.useState<any>({});
    const [selection, setSelection] = React.useState<string>("");

    React.useEffect(() => {
        setAllFranchises(franchisesShops.data);
        setFilteredFranchises(franchisesShops.data);
    }, [franchisesShops]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <Card className="px-3">
                    <CardBody className="mb-5 pt-3 mb-sm-2 mb-md-5 mb-lg-4 mb-xl-0 p-0">
                        <MySelectionsContext.Provider
                            value={{
                                setSearchedFranchises,
                                searchedFranchises,
                                setSelectedFranchiseValue,
                                selectedFranchiseValue,
                                setIsFranchiseChosen,
                                isFranchiseChosen,
                                setExpandedRows,
                                expandedRows,
                                setSelectedSelectionValue,
                                selectedSelectionValue,
                                selectedSelection,
                                setSelectedSelection,
                                selection,
                                setSelection,
                            }}
                        >
                            <CustomErrorBoundary>
                                <FranchisesSelection />
                            </CustomErrorBoundary>

                            <CustomErrorBoundary>
                                <FranchisesTable />
                            </CustomErrorBoundary>
                        </MySelectionsContext.Provider>
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
}
