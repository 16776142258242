import React from "react";
import { useSelector } from "react-redux";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import SalesModeRow from "./SalesModeRow";
import RegulationsRow from "./RegulationsRow";
import SalesSupportRow from "./SalesSupportRow";
import FamiliesRow from "./FamiliesRow";
import ServiceRow from "./ServiceRow";
import EmployeesRow from "./EmployeesRow";
import Top10ShopsRow from "./Top10ShopsRow";
import ProductsRow from "./ProductsRow";

import "@assets/grid.css";

export default function StatisticsCharts() {
    const type = useSelector((state: { type: string }) => state.type);
    const period = useSelector((state: { period: string }) => state.period);
    const selections = useSelector(
        (state: { selections: SelectionType }) => state.selections
    );

    const designation = selections ? selections.designation : undefined;

    return (
        <div className="d-grid__clz grid-gap__clz grid-template-col__clz grid-sm-template-col__clz grid-xs-template-col__clz pr-0">
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <SalesSupportRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <RegulationsRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <ServiceRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <FamiliesRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <ProductsRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <SalesModeRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <EmployeesRow
                        type={type}
                        period={period}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>
                    <Top10ShopsRow
                        period={period}
                        type={type}
                        designation={designation}
                    />
                </CustomErrorBoundary>
            </React.Suspense>
        </div>
    );
}
