import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";
import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getStringsArrayUniqueValues,
    getTypeParam,
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
    getSalesSupportQueryString,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "../../CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function Top10FamiliesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: top10FamiliesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-support/percentage-family?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(statisticsStore.salesSupportCards.modes)
                .length > 0
                ? `&sales-support=${getSalesSupportQueryString(
                      statisticsStore.salesSupportCards.modes
                          .map(getUntranslatedString)
                          .map((el: any) => el?.toUpperCase())
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];
        top10FamiliesData.data?.forEach((item: any) => {
            localData.push(item.value);
            localLabels.push(item.designation);
        });

        setData(getRoundedArrayNumbers(localData.filter((item) => item !== 0)));
        setLabels(
            getStringsArrayUniqueValues(
                localLabels.length > 0
                    ? localLabels.map((item) => t(item))
                    : [t("No data")]
            )
        );
        setPercentageData!(localData);
    }, [top10FamiliesData, t, setPercentageData]);

    const donutChartTop10FamiliesData = {
        data,
        labels: labels.length > 0 ? labels : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Top 10 families")}
                        data={donutChartTop10FamiliesData}
                    >
                        <LegendPopoverWrapper
                            title={t("Top 10 families")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
