import React from "react";
import { Row, Container } from "reactstrap";
import { useStep } from "react-hooks-helper";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styled from "styled-components";

import PageTitle from "@components/Common/PageTitle";
import { PortalLogoSvgIcon } from "@components/Common/SvgIcons";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";

import "../Settings/Settings.css";
import "./index.css";
import "@assets/widths.css";

const Grid = styled.div`
    display: grid;
    grid-template-columns: 15% auto;
    grid-template-rows: auto;
    gap: 0px 0px;
`;

export default function Registration() {
    const { t } = useTranslation();

    const { index, navigation } = useStep({ steps: 2 });

    return (
        <React.Fragment>
            <PageTitle title={t("Registration")} />
            <Container
                fluid={true}
                className="page-content registration-header-container__clz pl-0 pt-0"
                style={{ minHeight: "calc(100vh - 67.5px)" }}
            >
                <video autoPlay loop muted>
                    <source src="background.mp4" type="video/mp4" />
                </video>
                <Row
                    style={{
                        paddingRight: "90px",
                        paddingLeft: "90px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                    }}
                >
                    <header className="d-flex flex-column mb-4 mt-4">
                        <PortalLogoSvgIcon />
                        <div className="text-uppercase registration-title__clz">
                            {t("Registration")}
                        </div>
                    </header>
                </Row>

                <Grid>
                    <div
                        className="px-0"
                        style={{
                            backgroundColor: "black",
                            minHeight: "600px",
                        }}
                    >
                        <div className="display-flex custom-margin-top-65 custom-margin-top-sm custom-margin-bottom-sm custom-margin-left-sm">
                            <div
                                className={`${classnames({
                                    "active-registration-step__clz":
                                        index === 0,
                                    "disable-registration-step__clz":
                                        index !== 0,
                                })} p-2 d-flex align-items-center`}
                            >
                                <span className="mx-3 font-weight-bold font-size-20">
                                    1
                                </span>
                                <span style={{ fontSize: "15px" }}>
                                    {t("Enter your information")}
                                </span>
                            </div>
                            <div
                                className={`${classnames({
                                    "active-registration-step__clz":
                                        index === 1,
                                    "disable-registration-step__clz":
                                        index !== 1,
                                })} p-2 d-flex align-items-center`}
                            >
                                <span className="mx-3 font-weight-bold font-size-20">
                                    2
                                </span>
                                <span style={{ fontSize: "15px" }}>
                                    {t("Activate your account")}
                                </span>
                            </div>
                        </div>
                    </div>
                    {index === 0 && <FirstStep {...navigation} />}
                    {index === 1 && <SecondStep {...navigation} />}
                </Grid>
            </Container>
        </React.Fragment>
    );
}
