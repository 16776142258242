import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Transactions/store";

export default function TitleRowButtons({
    title,
    next,
}: {
    title?: string;
    next?: any;
}) {
    const { t } = useTranslation();

    const [selection, setSelection] = React.useState<any[]>([]);

    const s = useSnapshot(store);

    React.useEffect(() => {
        if (title === t("Sales mode")) {
            setSelection(s.salesModeSelection);
        } else if (title === t("Sales support")) {
            setSelection(s.salesSupportSelection);
        } else if (title === t("Payment methods")) {
            setSelection(s.regulationModesSelection);
        } else if (title === t("Products")) {
            setSelection(s.productsSelection);
        } else if (title === t("Order city")) {
            setSelection(s.customersCitiesSelection);
        }
    }, [title, s, t]);

    return (
        <StyledButton
            rounded
            outline
            variant="secondary"
            disabled={selection.length === 0}
            className="white-space-nowrap"
            onClick={() => next()}
        >
            {`${t("My selection")} (${selection.length})`}
        </StyledButton>
    );
}
