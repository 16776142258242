import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    capitalize,
    getAdvancedPeriodStringParam,
    getRoundedArrayNumbers,
    getStringsArrayUniqueValues,
    getTypeParam,
    getSalesModesQueryString,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import DonutChart from "@pages/Statistics/CommonCharts/DonutChart";
import LegendPopoverWrapper from "@pages/Statistics/CommonCharts/DonutChart/LegendPopoverWrapper";

export default function ServicesCol() {
    const { t } = useTranslation();
    const {
        period,
        type,
        advancedDate,
        selections,
        setPercentageData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [data, setData] = React.useState<number[]>([]);
    const [labels, setLabels] = React.useState<string[]>([]);

    const { resourceData: servicesData, error } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-mode/percentage-service?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesModesQueryString(statisticsStore.salesModeCards.modes)
                .length > 0
                ? `&sales-mode=${getSalesModesQueryString(
                      statisticsStore.salesModeCards.modes.map(
                          getUntranslatedString
                      )
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        let localData: number[] = [];
        let localLabels: string[] = [];

        servicesData.data?.forEach((item: any) => {
            localLabels.push(item.designation);
            localData.push(item.value);
        });
        setData(getRoundedArrayNumbers(localData.filter((item) => item !== 0)));
        setLabels(
            getStringsArrayUniqueValues(
                localLabels.length > 0
                    ? localLabels.map((item) => t(item))
                    : [t("No data")]
            )
        );
        setPercentageData!(localData);
    }, [servicesData, t, setPercentageData]);

    const donutChartPurchaseServicesData = {
        data,
        labels:
            labels.length > 0
                ? labels.map((label) => t(capitalize(label)))
                : [t("No data")],
    };

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <DonutChart
                        title={t("Services")}
                        data={donutChartPurchaseServicesData}
                    >
                        <LegendPopoverWrapper
                            title={t("Services")}
                            labels={labels || []}
                        />
                    </DonutChart>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
