import React from "react";
import { Button, Card, CardBody, Nav } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";
import {
    setAdvancedDate,
    setAdvancedLegend,
    setAdvancedTypeParam,
    setSegmentQueryString,
} from "@pages/Statistics/Transactions/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getAdvancedSeeMorePeriod,
    getFormattedCategoriesByPeriod,
    getSalesSupportQueryString,
    getStatisticsData,
    getTypeParam,
    getUntranslatedString,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "@assets/apex-chart.css";

export default function StatisticsSalesModeCol() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        period,
        advancedDate,
        type,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const statisticsStore = useSnapshot(store);

    const [categories, setCategories] = React.useState<string[]>([]);

    const [salesModesData, setSalesModesData] = React.useState<
        SeriesDataType[]
    >([]);

    const {
        resourceData: statisticsSalesModesData,
        error: statisticsSalesModesDataError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/sales-support/sales-mode?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getSalesSupportQueryString(statisticsStore.salesSupportCards.modes)
                .length > 0
                ? `&sales-support=${getSalesSupportQueryString(
                      statisticsStore.salesSupportCards.modes
                          .map(getUntranslatedString)
                          .map((el: any) => el?.toUpperCase())
                  )}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsSalesModesData.data);
        setSalesModesData(
            getStatisticsData(
                statisticsSalesModesData.data,
                categories,
                period,
                advancedDate
            )
        );
    }, [
        statisticsSalesModesData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
    ]);

    function handleSeeMoreButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "sales_support",
                    value: [
                        ...statisticsStore.salesSupportCards.modes
                            .filter(
                                (mode: string) =>
                                    mode !== t("All sales supports")
                            )
                            .map((item: string) => {
                                return getUntranslatedString(
                                    item
                                ).toUpperCase();
                            }),
                    ],
                },
            ],
        };

        setAdvancedLegend(t("Sales mode"));
        setAdvancedTypeParam(type);
        setSegmentQueryString(encodeURI(JSON.stringify(segmentObject)));

        setAdvancedDate(getAdvancedSeeMorePeriod(period, advancedDate));

        history.push("/statistics-advanced", { prevUrl: location.pathname });
    }

    return (
        <React.Fragment>
            {statisticsSalesModesDataError ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <Card>
                        <CardBody className="card-body__clz">
                            <Nav
                                pills
                                className="navtab-bg nav__clz d-none"
                                style={{ float: "right" }}
                            >
                                <Button
                                    color="secondary"
                                    outline
                                    size="sm"
                                    onClick={handleSeeMoreButtonOnClickEvent}
                                >
                                    {t("See more")}{" "}
                                    <i className="mdi mdi-arrow-right ml-1"></i>
                                </Button>
                            </Nav>
                            <div className="apex-charts apex-chart__clz">
                                <ChartWrapper
                                    series={salesModesData}
                                    categories={categories}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
