import React from "react";
import { Card, CardBody, Nav, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getFamiliesFilterQueryString,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";

export default function StatisticsServicesCol() {
    const { t } = useTranslation();

    const {
        type,
        period,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
        servicesSelection,
    } = React.useContext(StatisticsContext);

    const [categories, setCategories] = React.useState<string[]>([]);
    const [top10FamiliesData, setTop10FamiliesData] = React.useState<any[]>([]);

    const {
        resourceData: statisticsFamiliesService,
        error: statisticsFamiliesSalesServiceError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/families/service?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            getFamiliesFilterQueryString(servicesSelection!) !== ""
                ? `&service=${getFamiliesFilterQueryString(servicesSelection!)}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setTop10FamiliesData(
            getStatisticsData(
                statisticsFamiliesService.data,
                categories,
                period,
                advancedDate
            )
        );
        setHistogramData!(statisticsFamiliesService.data);
    }, [
        statisticsFamiliesService,
        categories,
        period,
        advancedDate,
        setHistogramData,
        t,
    ]);

    return (
        <Card>
            <CardBody className="card-body__clz card-body__clz-sm card-body__clz-md card-body__clz-lg">
                <Nav
                    pills
                    className="navtab-bg nav__clz nav__clz-sm nav__clz-md nav__clz-lg"
                >
                    <Link
                        style={{ paddingTop: "8px" }}
                        to="/turnover-detail"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <CardBody className="pb-0 chart-order chart-order-sm chart-order-md chart-order-lg">
                    <TabPane className="p-2">
                        <div className="apex-charts">
                            {statisticsFamiliesSalesServiceError ? (
                                <ErrorPage />
                            ) : (
                                <CustomErrorBoundary>
                                    <ChartWrapper
                                        series={top10FamiliesData}
                                        categories={categories}
                                    />
                                </CustomErrorBoundary>
                            )}
                        </div>
                    </TabPane>
                </CardBody>
            </CardBody>
        </Card>
    );
}
