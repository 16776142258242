import React from "react";
import { Card, CardBody, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/Employees/store";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import {
    getAdvancedPeriodStringParam,
    getFormattedCategoriesByPeriod,
    getStatisticsData,
    getTypeParam,
} from "@helpers/general";

import ChartWrapper from "@pages/Statistics/CommonCharts/ChartWrapper";
import ErrorPage from "@components/Common/ErrorPage";

export default function RecapTop10Employees() {
    const { t } = useTranslation();

    const { employeesQueryString } = useSnapshot(store);

    const {
        period,
        type,
        advancedDate,
        selections,
        setHistogramData,
        setCategoriesData,
    } = React.useContext(StatisticsContext);

    const [top10EmployeesData, setTop10EmployeesData] = React.useState<any[]>(
        []
    );
    const [categories, setCategories] = React.useState<any[]>([]);

    const {
        resourceData: statisticsTop10EmployeesData,
        error: statisticsTop10EmployeesError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/employees/cashier/recap?type=${getTypeParam(
            type
        )}&${getAdvancedPeriodStringParam(period, advancedDate)}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${
            employeesQueryString !== ""
                ? `&cashier=${employeesQueryString}`
                : ""
        }`
    );

    React.useEffect(() => {
        setCategories(getFormattedCategoriesByPeriod(period, advancedDate));
        setCategoriesData!(
            getFormattedCategoriesByPeriod(period, advancedDate)
        );
    }, [period, advancedDate, setCategoriesData]);

    React.useEffect(() => {
        setHistogramData!(statisticsTop10EmployeesData.data);
        setTop10EmployeesData(
            selections.selection.length === 1
                ? getStatisticsData(
                      statisticsTop10EmployeesData.data,
                      categories,
                      period,
                      advancedDate
                  ).map((item) => {
                      return {
                          name: item.name?.split("|")[0],
                          data: item.data,
                      };
                  })
                : getStatisticsData(
                      statisticsTop10EmployeesData.data,
                      categories,
                      period,
                      advancedDate
                  )
        );
    }, [
        statisticsTop10EmployeesData,
        categories,
        period,
        advancedDate,
        t,
        setHistogramData,
        selections,
    ]);

    return (
        <Card>
            <CardBody className="card-body__clz">
                <Nav pills className="d-none navtab-bg nav__clz">
                    <Link
                        to="/coming-soon"
                        className="btn btn-outline-dark cards btn-sm float-right d-none"
                    >
                        {t("See more")}{" "}
                        <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                </Nav>
                <CardBody className="pb-2 chart-order">
                    {statisticsTop10EmployeesError ? (
                        <ErrorPage />
                    ) : (
                        <div className="apex-charts">
                            <ChartWrapper
                                series={top10EmployeesData}
                                categories={categories}
                            />
                        </div>
                    )}
                </CardBody>
            </CardBody>
        </Card>
    );
}
