import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

type PropsType = {
    toggleOpeningModal: () => void;
};

export default function SaveMySelectionButtonWrapper({
    toggleOpeningModal,
}: PropsType) {
    const { t } = useTranslation();

    return (
        <Button
            type="button"
            color="dark"
            className="px-0 w-xs-100 w-sm-100 mt-2 mt-md-0 font-size-sm franchises-font-size-xl__clz"
            onClick={toggleOpeningModal}
        >
            {t("Save my selection")}
        </Button>
    );
}
