import React from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useTable, useRowSelect } from "react-table";
import BTable from "react-bootstrap/Table";
import { Button } from "reactstrap";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import { useStep } from "react-hooks-helper";
import { useSelector } from "react-redux";

import store, {
    addProductsSelectionItem,
    setProductsNumber,
    setMaxAvgBasket,
    setAllProducts,
} from "../store";

import useResource from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import {
    getAdvancedProductsData,
    isItemAvgBasketRangeIsIncludedInAvgBasketRange,
    isItemAvgBasketValueIsInAvgBasketRange,
    getAdvancedProductsMaxAvgBasketValue,
} from "@helpers/general";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import AddSvgIcon from "@components/Common/SvgIcons/AddSvgIcon";
import CustomSelect from "@pages/Settings/Articles/CustomSelect";

import Pagination from "../Table/Pagination";
import TitleRowButtons from "../SegmentContent/TitleRowsButtons";
import MySelection from "./MySelection";

const Styles = styled.div`
    table {
        th:first-child {
            width: 5%;
        }
    }
`;

type PropsType = { title: string };

export default function Products({ title }: PropsType) {
    const { t } = useTranslation();

    const advancedStore = useSnapshot(store);
    const selections = useSelector((state: any) => state.selections);

    const { index: currentPageIndex, navigation } = useStep({ steps: 2 });

    const productsSearchInputRef = React.useRef<HTMLInputElement>(null);

    const [products, setProducts] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any[]>([]);
    const [
        filteredProductsByAvgBasket,
        setFilteredProductsByAvgBasket,
    ] = React.useState<any[]>([]);

    const [familiesSelectedValue, setFamiliesSelectedValue] = React.useState<
        any[]
    >([]);
    const [
        subFamiliesSelectedValue,
        setSubFamiliesSelectedValue,
    ] = React.useState<any[]>([]);

    const [filteredProducts, setFilteredProducts] = React.useState<any[]>([]);

    const [families, setFamilies] = React.useState<string[]>([]);
    const [subFamilies, setSubFamilies] = React.useState<string[]>([]);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/products${
            selections.designation && selections.designation !== "my_favorites"
                ? `?selected=${selections.designation}`
                : ""
        }`
    );

    function handleSearchInputOnChangeEvent(searchedValue: string) {
        let localProducts: any[];

        if (
            familiesSelectedValue !== null &&
            familiesSelectedValue!.length > 0
        ) {
            localProducts = products.filter((product) => {
                return (
                    product.designation
                        .toUpperCase()
                        .includes(searchedValue.toUpperCase()) &&
                    familiesSelectedValue
                        .map((element) => element.value.toUpperCase())
                        .includes(product.families.toUpperCase())
                );
            });
        } else if (
            subFamiliesSelectedValue !== null &&
            subFamiliesSelectedValue!.length > 0
        ) {
            localProducts = products.filter((product) => {
                return (
                    product.designation
                        .toUpperCase()
                        .includes(searchedValue.toUpperCase()) &&
                    subFamiliesSelectedValue
                        .map((element) => element.value.toUpperCase())
                        .includes(product.sub_families.toUpperCase())
                );
            });
        } else {
            localProducts = products.filter((product) => {
                return product.designation
                    .toUpperCase()
                    .includes(searchedValue.toUpperCase());
            });
        }
        setFilteredProducts(localProducts);
    }

    function handleMultipleAddingButtonOnClickEvent() {
        selectedFlatRows.forEach((row: any) => {
            addProductsSelectionItem(row.original);
        });

        let localProducts: any[] = filteredProducts.filter((product) => {
            return !selectedFlatRows
                .map((row: any) =>
                    `${row.original.reference}|${row.original.designation}|${row.original.families}|${row.original.sub_families}`.toLowerCase()
                )
                .includes(
                    `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase()
                );
        });
        setFilteredProducts(localProducts);
    }

    const handleAddingProductsButtonOnClickEvent = React.useCallback(
        (product: any) => {
            addProductsSelectionItem(product);

            let localProducts: any[] = filteredProducts.filter((datum: any) => {
                return (
                    `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase() !==
                    `${datum.reference}|${datum.designation}|${datum.families}|${datum.sub_families}`.toLowerCase()
                );
            });
            setFilteredProducts(localProducts);
        },
        [filteredProducts]
    );

    const columns: AdvancedTableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Families"),
                accessor: "families",
            },
            {
                Header: t("Sub-families"),
                accessor: "sub_families",
            },
            {
                Header: "",
                id: "actions",
                accessor: function (product: any) {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="secondary"
                                outline
                                block
                                style={{
                                    width: "120px",
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block"
                                onClick={() =>
                                    handleAddingProductsButtonOnClickEvent(
                                        product
                                    )
                                }
                            >
                                {t("Add")}
                            </Button>
                            <button
                                className="border-0 d-block d-sm-block d-md-none p-1 bg-transparent__clz icon-color__clz"
                                onClick={() =>
                                    handleAddingProductsButtonOnClickEvent(
                                        product
                                    )
                                }
                            >
                                <AddSvgIcon />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingProductsButtonOnClickEvent]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t(rest.title) };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredProducts,
            initialState: { pageIndex: 0 },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        let localAdvancedProductsData = getAdvancedProductsData(
            resourceData.data
        );

        if (currentPageIndex === 1) {
            setSubFamiliesSelectedValue([]);
            setFamiliesSelectedValue([]);
        }

        setData(localAdvancedProductsData);
        setAllProducts(localAdvancedProductsData);
        setMaxAvgBasket(
            getAdvancedProductsMaxAvgBasketValue(resourceData.data)
        );
    }, [resourceData, currentPageIndex]);

    React.useEffect(() => {
        let localProducts: any[] = [];

        data.forEach((item: any) => {
            if (item.average_basket.length > 1) {
                if (
                    isItemAvgBasketRangeIsIncludedInAvgBasketRange(
                        item,
                        advancedStore.avgBasketRange
                    )
                ) {
                    localProducts.push(item);
                }
            } else if (
                isItemAvgBasketValueIsInAvgBasketRange(
                    item,
                    advancedStore.avgBasketRange
                )
            ) {
                localProducts.push(item);
            }
        });

        setFilteredProductsByAvgBasket(localProducts);
        setProductsNumber(data.length);
    }, [data, advancedStore]);

    React.useEffect(() => {
        let localData =
            advancedStore.productsSelection.length > 0
                ? data.filter((product: any) => {
                      return !advancedStore.productsSelection
                          .map((selectedProduct) =>
                              `${selectedProduct.reference}|${selectedProduct.designation}|${selectedProduct.families}|${selectedProduct.sub_families}`.toLowerCase()
                          )
                          .includes(
                              `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase()
                          );
                  })
                : data;

        let localFilteredProductsByAvgBasket =
            advancedStore.productsSelection.length > 0
                ? filteredProductsByAvgBasket.filter((product: any) => {
                      return !advancedStore.productsSelection
                          .map((selectedProduct) =>
                              `${selectedProduct.reference}|${selectedProduct.designation}|${selectedProduct.families}|${selectedProduct.sub_families}`.toLowerCase()
                          )
                          .includes(
                              `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`.toLowerCase()
                          );
                  })
                : filteredProductsByAvgBasket;

        setProducts(
            advancedStore.avgBasketRange.length === 0
                ? localData
                : localFilteredProductsByAvgBasket
        );
    }, [filteredProductsByAvgBasket, advancedStore, data]);

    React.useEffect(() => {
        let localFamilies: string[] = [];
        let localSubFamilies: string[] = [];

        products.forEach((product: any) => {
            if (product.families !== "") {
                localFamilies.push(product.families);
            }

            if (product.sub_families !== "") {
                localSubFamilies.push(product.sub_families);
            }
        });

        if (
            (familiesSelectedValue === null ||
                familiesSelectedValue!.length === 0) &&
            (subFamiliesSelectedValue === null ||
                subFamiliesSelectedValue!.length === 0)
        ) {
            setFilteredProducts(products);
        }

        setFamilies([...new Set(localFamilies)]);
        setSubFamilies([...new Set(localSubFamilies)]);
    }, [products, familiesSelectedValue, subFamiliesSelectedValue]);

    let localFamilies: any[] = families.map((family: string) => ({
        label: family,
        value: family,
    }));

    let localSubFamilies: any[] = subFamilies.map((subFamily: string) => ({
        label: subFamily,
        value: subFamily,
    }));

    function handleFamiliesSelectOnChangeEvent(
        familiesSelectedValue: any,
        action: any
    ) {
        setSubFamiliesSelectedValue([]);
        setFilteredProducts(products);

        setFamiliesSelectedValue(familiesSelectedValue);
        if (action.action === "clear") {
            setFilteredProducts(products);
        } else {
            if (familiesSelectedValue) {
                let filteredProductsByFamilies: any[] = [];
                familiesSelectedValue!.forEach((item: any) => {
                    products.forEach((article) => {
                        if (article.families === item.value) {
                            filteredProductsByFamilies.push(article);
                        }
                    });
                });
                productsSearchInputRef!.current!.value = "";
                setFilteredProducts(filteredProductsByFamilies);
            }
        }
    }

    function handleSubFamiliesSelectOnChangeEvent(
        subFamiliesSelectedValue: any,
        action: any
    ) {
        setSubFamiliesSelectedValue(subFamiliesSelectedValue);
        setFilteredProducts(products);
        setFamiliesSelectedValue([]);

        if (action.action === "clear") {
            setFilteredProducts(products);
        } else {
            if (subFamiliesSelectedValue) {
                let filteredProductsBySubFamilies: any[] = [];
                subFamiliesSelectedValue!.forEach((item: any) => {
                    products.forEach((article) => {
                        if (article.sub_families === item.value) {
                            filteredProductsBySubFamilies.push(article);
                        }
                    });
                });
                productsSearchInputRef!.current!.value = "";
                setFilteredProducts(filteredProductsBySubFamilies);
            }
        }
    }

    return (
        <React.Fragment>
            {currentPageIndex === 0 && (
                <React.Fragment>
                    <div className="d-flex justify-content-end">
                        <Button
                            type="button"
                            color="secondary"
                            className="my-2 white-space-nowrap"
                            disabled={selectedFlatRows.length === 0}
                            onClick={handleMultipleAddingButtonOnClickEvent}
                        >
                            {t("Add")}
                        </Button>
                        <TitleRowButtons {...navigation} title={title} />
                    </div>
                    <div className="d-flex mb-2">
                        <div className="app-search p-0 mx-2">
                            <div className="position-relative">
                                <input
                                    ref={productsSearchInputRef}
                                    type="text"
                                    className="form-control"
                                    placeholder={`${t("Search")} ...`}
                                    onChange={(e) =>
                                        handleSearchInputOnChangeEvent(
                                            e.target.value
                                        )
                                    }
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                        <CustomSelect
                            options={localFamilies}
                            placeholder={t("Families")}
                            handleOnChangeEvent={
                                handleFamiliesSelectOnChangeEvent
                            }
                            selectedValue={familiesSelectedValue}
                        />
                        <CustomSelect
                            options={localSubFamilies}
                            placeholder={t("Sub-families")}
                            handleOnChangeEvent={
                                handleSubFamiliesSelectOnChangeEvent
                            }
                            selectedValue={subFamiliesSelectedValue}
                        />
                    </div>
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <Styles>
                                <BTable responsive {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => (
                                                        <th
                                                            className="white-space-nowrap"
                                                            {...column.getHeaderProps()}
                                                        >
                                                            {column.render(
                                                                "Header"
                                                            )}
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td
                                                                className="white-space-nowrap"
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </BTable>
                            </Styles>
                            {filteredProducts.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </React.Fragment>
            )}
            {currentPageIndex === 1 && (
                <MySelection
                    {...navigation}
                    products={advancedStore.productsSelection}
                />
            )}
        </React.Fragment>
    );
}
